import { Route } from "vue-router/types";
import { UserRole } from "@/graphql";
import { CustomRouteConfig } from "./routes";
import { Preferences } from "@capacitor/preferences";
import { useUserStore } from "@/store/modules/user";
import pinaStore from "@/store/index";
import defaultRoutes from "@/config/defaultRoutes";
export default async function (to: Route, from: Route, next: any) {
	const User = useUserStore(pinaStore);

	const requireAuth = to.matched.some((m) => m.meta?.requireAuth);
	let requireRole: UserRole | undefined;

	to.matched.some((record) => {
		if (record.meta?.requireRole) {
			requireRole = record.meta.requireRole;
			return true; // Break the loop once a requireRole is found.
		}
		return false; // Continue searching through matched routes if no requireRole is found.
	});

	const { value: token } = await Preferences.get({ key: "PLATFORM_TOKEN" });

	const title = `${to.meta?.title} | ${import.meta.env.VITE_PLATFORM_NAME}`;
	document.title = title;

	let isMaintenance = import.meta.env.VITE_IS_MAINTENANCE === "true";

	if (isMaintenance && to.name !== "UnderMaintenance") {
		return next({ name: "UnderMaintenance" });
	}
	if (!isMaintenance && to.name === "UnderMaintenance") {
		return next({ path: "/" });
	}

	if (requireAuth && !token) {
		next({ name: "SignIn" });
	} else if (token) {
		const user = User.user || (await User.initUser());

		if (requireRole && requireRole !== user?.role) {
			const userRole = user?.role || 'User'; // Fallback to User if role is undefined
			const homeRoute = defaultRoutes[userRole]?.home || 'User.Home'; // Fallback to User.Home if route is empty
			next({ name: homeRoute });
		} else if (to.name === "SignIn" || to.name === "SignUp") {
			// With this universal links such as https://Bookli.app/u/calendar will still be redirected to home
			// Without it the user will become stuck on the sign in page on app cold start but the universal link redirect will work.
			// The above issue has been solved by introducing a timeout for the router.push in the CapacitorApp.addListener("appUrlOpen") in main.ts
			// Just using next() causes more issues.
			// TODO: Given more time the before guard (if it is the actual root cause) should be updated.
			const userRole = user?.role || 'User'; // Fallback to User if role is undefined
			const homeRoute = defaultRoutes[userRole]?.home || 'User.Home'; // Fallback to User.Home if route is empty
			next({ name: homeRoute });
		} else if (to.meta?.requireEcomEnabled && !User.user?.ecomAvailable) {
			const userRole = user?.role || 'User'; // Fallback to User if role is undefined
			const homeRoute = defaultRoutes[userRole]?.home || 'User.Home'; // Fallback to User.Home if route is empty
			next({ name: homeRoute });
		} else {
			next();
		}
	} else {
		next();
	}
}
