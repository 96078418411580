import { ApolloClient, ApolloError, ApolloLink, InMemoryCache, split, Observable } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { setContext } from "@apollo/client/link/context"
import { RetryLink } from "@apollo/client/link/retry" // Add this import
import VueApollo from 'vue-apollo'
import { createUploadLink } from 'apollo-upload-client'
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"
import { getMainDefinition } from 'apollo-utilities'
import { Preferences } from '@capacitor/preferences'
import { usePlatformStore } from '@/store/modules/platform'
import pinaStore from '@/store/index'
import * as Sentry from '@sentry/vue'
import { InvariantError } from '@apollo/client/utilities/globals'
import { useDebugStore } from '@/store/debugStore'

// Constants for reset handling
const RESET_TIMEOUT = 4000;
const MIN_RESET_INTERVAL = 2000;

// State management
let isResetting = false;
let resetPromise: Promise<void> | null = null;
let lastResetTime = 0;
let pendingOperations = new Set<string>();

interface OperationTiming {
  startTime: number;
  operationName: string;
}

const operationTimings = new Map<string, OperationTiming>();

// Authorization helper
async function getAuthorization() {
  const { value: platformToken } = await Preferences.get({ key: 'PLATFORM_TOKEN' })
  const { value: userToken } = await Preferences.get({ key: 'USER_TOKEN' })
  const token = platformToken || userToken
  return token ? `Bearer ${token}` : ''
}

function generateUUID(): string {
  if (typeof crypto !== 'undefined' && crypto.randomUUID) {
    return crypto.randomUUID();
  }
  
  // Fallback implementation for older browsers
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

// Add network quality check function
function getNetworkQuality() {
  // NetworkInformation API isn't standard, so we need to use type assertion
  interface NetworkInformation {
    effectiveType?: string;
    type?: string;
    downlink?: number;
    rtt?: number;
    saveData?: boolean;
  }
  
  // Type assertion for navigator
  interface NavigatorWithConnection extends Navigator {
    connection?: NetworkInformation;
    mozConnection?: NetworkInformation;
    webkitConnection?: NetworkInformation;
  }
  
  if (typeof navigator === 'undefined') {
    return { effectiveType: 'unknown' };
  }
  
  const nav = navigator as NavigatorWithConnection;
  const connection = nav.connection || nav.mozConnection || nav.webkitConnection;
  
  if (!connection) {
    return { effectiveType: 'unknown' };
  }
  
  return {
    type: connection.type || 'unknown',
    effectiveType: connection.effectiveType || 'unknown',
    downlink: connection.downlink,
    rtt: connection.rtt,
    saveData: connection.saveData
  };
}

// HTTP link setup with timeout
const httpLink = createUploadLink({
  uri: import.meta.env.VITE_GRAPHQL_API,
  // Note: fetchOptions may not be supported in createUploadLink
  // Consider using a custom fetch implementation if needed
  fetch: (uri: RequestInfo, options: RequestInit) => {
    // Set a timeout for the fetch request
    const controller = new AbortController();
    const { signal } = controller;
    
    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 20000); // 20 second timeout
    
    return fetch(uri, {
      ...options,
      signal
    }).finally(() => {
      clearTimeout(timeoutId);
    });
  }
})

// Auth link setup
const authLink = setContext(async ({ operationName }, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-apollo-operation-name': operationName,
      'x-request-id': generateUUID(),
      authorization: await getAuthorization()
    }
  }
})

// Add RetryLink for network errors
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: 3000,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: (error: any, operation: any) => {
      // Only retry on network errors, not user errors
      const isNetworkError = error?.message === 'Load failed' || 
                            (error?.networkError && typeof navigator !== 'undefined' && navigator.onLine);
      
      // Log retry attempts
      if (isNetworkError) {
        console.log(`Retrying operation ${operation.operationName} due to network error`);
        
        Sentry.addBreadcrumb({
          category: 'apollo-retry',
          message: `Retrying operation: ${operation.operationName}`,
          data: {
            error: error?.message,
            networkOnline: typeof navigator !== 'undefined' ? navigator.onLine : 'unknown',
            timestamp: new Date().toISOString()
          },
          level: 'info'
        });
      }
      
      return isNetworkError;
    }
  }
});

// Network state awareness link
const networkAwarenessLink = new ApolloLink((operation, forward) => {
  // For offline scenarios, try to handle gracefully
  if (typeof navigator !== 'undefined' && navigator.onLine === false) {
    const definition = getMainDefinition(operation.query);
    const isQuery = definition.kind === 'OperationDefinition' && 
                   definition.operation === 'query';
    
    // For queries, we can try to serve from cache when offline
    if (isQuery) {
      Sentry.addBreadcrumb({
        category: 'network',
        message: `Offline operation: ${operation.operationName}`,
        level: 'info'
      });
      
      // Continue with the operation - the cache will be used as we're offline
      return forward(operation);
    }
    
    // For mutations while offline, we can either queue them or reject
    if (definition.kind === 'OperationDefinition' && 
        definition.operation === 'mutation') {
      
      Sentry.addBreadcrumb({
        category: 'network',
        message: `Offline mutation rejected: ${operation.operationName}`,
        level: 'warning'
      });
      
      // For now, reject mutations when offline
      return new Observable(observer => {
        observer.error(new Error('Network unavailable for mutation'));
      });
    }
  }
  
  // Otherwise proceed normally
  return forward(operation);
});

// Operation tracking link
// Operation tracking link
const operationTrackingLink = new ApolloLink((operation, forward) => {
  const operationName = operation.operationName || 'anonymous';
  const timing: OperationTiming = {
    startTime: Date.now(),
    operationName
  };
  
  operationTimings.set(operationName, timing);
  pendingOperations.add(operationName);
  
  Sentry.addBreadcrumb({
    category: 'graphql',
    message: `Operation started: ${operationName}`,
    data: {
      variables: operation.variables,
      startTime: new Date(timing.startTime).toISOString()
    },
    level: 'info'
  });

  // Capture current state for cleanup in case of errors
  const cleanup = () => {
    operationTimings.delete(operationName);
    pendingOperations.delete(operationName);
  };

  // Create subscription that cleans up on error
  return new Observable(observer => {
    // Only subscribe to forward once
    const subscription = forward(operation).subscribe({
      next: (result) => {
        const timing = operationTimings.get(operationName);
        const duration = timing ? Date.now() - timing.startTime : 0;
        
        cleanup();
        
        Sentry.addBreadcrumb({
          category: 'graphql',
          message: `Operation completed: ${operationName}`,
          data: {
            duration,
            hasErrors: !!result.errors,
            timestamp: new Date().toISOString()
          },
          level: result.errors ? 'error' : 'info'
        });
        
        observer.next(result);
      },
      error: (error: Error) => {
        cleanup();
        
        Sentry.addBreadcrumb({
          category: 'graphql',
          message: `Operation failed: ${operationName}`,
          data: {
            error: error.message,
            timestamp: new Date().toISOString()
          },
          level: 'error'
        });
        
        observer.error(error);
      },
      complete: () => {
        observer.complete();
      }
    });
    
    // Return unsubscribe function
    return () => {
      subscription.unsubscribe();
    };
  });
});

// HTTP link with auth
const httpLinkAuth = authLink.concat(httpLink as unknown as ApolloLink)

// WebSocket state
let wsIsConnected = false;
let wsClient: any = null;

// WebSocket error handling
function handleWebSocketError(error: unknown, context: string = 'general') {
  console.error(`WebSocket ${context} error:`, error);

  if (error instanceof InvariantError) {
    Sentry.captureMessage('WebSocket invariant error', {
      level: 'warning',
      tags: { errorType: 'WebSocketInvariantError', context },
      extra: { error: error.toString() }
    });
    return;
  }

  if (error instanceof Event) {
    const errorContext = {
      type: error.type,
      timestamp: error.timeStamp,
      target: error.target instanceof WebSocket ? {
        url: (error.target as WebSocket).url,
        readyState: (error.target as WebSocket).readyState,
        protocol: (error.target as WebSocket).protocol
      } : 'unknown',
      isTrusted: error.isTrusted
    };

    Sentry.captureMessage('WebSocket Event Error', {
      level: 'error',
      tags: { errorType: 'WebSocketEventError', eventType: error.type, context },
      extra: errorContext
    });
    return;
  }

  Sentry.captureException(error instanceof Error ? error : new Error('Unknown WebSocket error'), {
    tags: { errorType: 'WebSocketError', context },
    extra: { originalError: error }
  });
}

// Improved WebSocket link setup
export const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_GRAPHQL_SUBSCRIPTIONS as string,
    connectionParams: async () => {
      return {
        authorization: await getAuthorization(),
      };
    },
    on: {
      connected: () => {
        const Platform = usePlatformStore(pinaStore);
        wsIsConnected = true;
        Platform.setConnectedToGraphqlApi(true);
        
        Sentry.addBreadcrumb({
          category: 'websocket',
          message: 'WebSocket connected',
          level: 'info'
        });
      },
      closed: async () => {
        const Platform = usePlatformStore(pinaStore);
        wsIsConnected = false;
        
        if (apolloClient) {
          try {
            await apolloClient.stop();
          } catch (error) {
            handleWebSocketError(error, 'stop');
          }
        }

        setTimeout(() => {
          if (!wsIsConnected) {
            Platform.setConnectedToGraphqlApi(false);
          }
        }, 1000 * 10);
      },
      error: (error) => handleWebSocketError(error, 'event'),
      connecting: () => {
        Sentry.addBreadcrumb({
          category: 'websocket',
          message: 'WebSocket connecting',
          level: 'info'
        });
      },
    },
    shouldRetry: (errOrCloseEvent) => {
      const retryContext = errOrCloseEvent instanceof Event 
        ? { type: (errOrCloseEvent as Event).type }
        : { error: String(errOrCloseEvent) };
      
      Sentry.addBreadcrumb({
        category: 'websocket',
        message: 'WebSocket retry attempt',
        data: retryContext,
        level: 'info'
      });
      
      return true;
    },
    retryAttempts: 5,
    retryWait: (retries) => {
      const delay = Math.min(1000 * Math.pow(2, retries), 30000);
      console.log(`WebSocket reconnecting in ${delay}ms (attempt ${retries})`);
      return new Promise(resolve => setTimeout(resolve, delay));
    },
  })
);

// Enhanced error link setup
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const operationInfo = {
    query: operation.query.loc?.source?.body,
    variables: operation.variables,
    operationName: operation.operationName,
    operationType: operation.query.definitions[0]?.kind === 'OperationDefinition' 
      ? operation.query.definitions[0].operation 
      : undefined
  };

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { code } = error.extensions || {};

      if (code === 'INVALID_CREDENTIALS' || 
        code === 'ErrorEmailOrPassword' || 
        error.message === 'Invalid email or password' || 
        code === 'PHONE_NUMBER_IN_USE' || 
        error.message === 'Phone number already in use' ||
        error.message === 'You have to be a user or company' || // Add this condition
        code === 'AUTHENTICATION_ERROR') { // And this one for the code
      // Optionally log to console in dev mode only
      if (import.meta.env.DEV) {
        console.info(`[Auth Error] ${error.message}`);
      }
      return;
    }
      
      const errorDetails = {
        message: error.message,
        path: error.path,
        locations: error.locations,
        extensions: error.extensions,
        operation: operationInfo,
        timestamp: new Date().toISOString()
      };

      const expectedErrors = [
        'INVALID_CREDENTIALS',    
        'AUTHENTICATION_ERROR',   
        'VALIDATION_ERROR',       
        'PHONE_NUMBER_IN_USE',    
        'EMAIL_IN_USE',
        'VAT_NUMBER_IN_USE',
        'ErrorEmailOrPassword',
        'ErrorSignInWithFacebook',
        'ErrorSignInWithApple',
        'ErrorCouldNotValidate',
        'Phone number already in use',
        'Email already in use'
      ];

      if (expectedErrors.includes(code) || expectedErrors.some(err => error.message.includes(err))) {
        Sentry.captureMessage(error.message, {
          level: 'info',
          tags: {
            errorType: code || 'ExpectedError',
            operationName: operation.operationName || 'UnknownOperation',
            validationField: error.extensions?.field
          },
          extra: errorDetails,
        });
        return;
      }

      Sentry.captureException(new ApolloError({
        graphQLErrors: [error],
        errorMessage: error.message,
      }), {
        tags: {
          errorType: code || 'UnknownError',
          operationName: operation.operationName || 'UnknownOperation',
        },
        extra: errorDetails,
      });
    });
  }

  if (networkError) {
    const networkErrorDetails = {
      ...operationInfo,
      statusCode: 'statusCode' in networkError ? networkError.statusCode : undefined,
      name: networkError.name,
      message: networkError.message,
      stack: networkError.stack
    };

    // Enhanced handling for "Load failed" error
    if (networkError.message === 'Load failed') {
      // Add diagnostic information
      const timing = operationTimings.get(operation.operationName);
      const duration = timing ? Date.now() - timing.startTime : 0;
      const onlineStatus = typeof navigator !== 'undefined' ? navigator.onLine : 'unknown';
      const networkQuality = getNetworkQuality();
      
      console.warn(`Load failed for operation: ${operation.operationName}`, {
        duration: `${duration}ms`,
        wsConnected: wsIsConnected ? 'yes' : 'no',
        online: onlineStatus ? 'yes' : 'no',
        networkQuality,
        pendingOps: pendingOperations.size,
        variables: operation.variables
      });
      
      // Enhanced Sentry logging with more context
      Sentry.captureMessage('GraphQL request load failed', {
        level: 'error',
        tags: {
          errorType: 'LoadFailedError',
          operationName: operation.operationName || 'UnknownOperation',
          deviceOnline: onlineStatus ? 'yes' : 'no',
          wsConnected: wsIsConnected ? 'yes' : 'no',
          operationDuration: duration > 10000 ? 'slow' : 'normal',
          connectionType: typeof networkQuality.effectiveType === 'string' ? networkQuality.effectiveType : 'unknown'
        },
        extra: {
          ...networkErrorDetails,
          networkOnline: onlineStatus,
          duration,
          pendingOperations: Array.from(pendingOperations),
          userAgent: typeof navigator !== 'undefined' ? navigator.userAgent : 'unknown'
        },
      });
      
      // We're using RetryLink instead of manually handling retries here
    }

    Sentry.captureException(new ApolloError({
      networkError,
      errorMessage: networkError.message,
    }), {
      tags: {
        errorType: 'NetworkError',
        operationName: operation.operationName || 'UnknownOperation',
        statusCode: networkErrorDetails.statusCode,
      },
      extra: networkErrorDetails,
    });
  }
});

// Split link setup
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLinkAuth,
);

// Debug link setup
const debugLink = new ApolloLink((operation, forward) => {
  if (import.meta.env.DEV) {
    console.log(`[GraphQL Request] ${operation.operationName}`, {
      variables: operation.variables,
      query: operation.query.loc?.source?.body,
    });
  }

  return forward(operation).map(response => {
    if (import.meta.env.DEV && response.errors) {
      console.error(`[GraphQL Error] ${operation.operationName}`, {
        errors: response.errors,
        operation: operation.operationName,
        variables: operation.variables
      });
    }
    return response;
  });
});

// Function to safely stop client
async function safeStopClient(): Promise<void> {
  if (!wsIsConnected) return;
  
  try {
    await apolloClient.stop();
    wsIsConnected = false;
  } catch (error) {
    console.warn('Error stopping client:', error);
  }
}

// Enhanced store reset function
export async function safeResetStore(force: boolean = false): Promise<void> {
  const now = Date.now();
  
  if (!force && now - lastResetTime < MIN_RESET_INTERVAL) {
    return;
  }

  if (isResetting) {
    if (resetPromise) {
      try {
        await resetPromise;
        return;
      } catch (error) {
        if (!force) return;
      }
    }
    return;
  }

  isResetting = true;
  
  try {
    resetPromise = (async () => {
      // Stop subscriptions first
      await safeStopClient();

      // Wait a bit for operations to complete
      await new Promise(resolve => setTimeout(resolve, 100));

      try {
        // Try direct cache reset first
        await apolloClient.cache.reset();
        lastResetTime = Date.now();
      } catch (error) {
        console.warn('Cache reset failed, trying clearStore:', error);
        
        // Fallback to clearStore with timeout
        await Promise.race([
          apolloClient.clearStore(),
          new Promise((_, reject) => 
            setTimeout(() => reject(new Error('Store reset timeout')), RESET_TIMEOUT)
          )
        ]);
        
        lastResetTime = Date.now();
      }
    })();

    await resetPromise;
  } catch (error) {
    Sentry.captureException(error, {
      tags: { errorType: 'StoreResetError' },
      extra: {
        pendingOperations: Array.from(pendingOperations),
        isResetting,
        forced: force,
        wsConnected: wsIsConnected
      }
    });
    throw error;
  } finally {
    isResetting = false;
    resetPromise = null;
  }
}

// Improved Apollo client setup with modified policies
const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    retryLink,  // Add the retry link
    networkAwarenessLink,  // Add network awareness
    debugLink,
    operationTrackingLink,
    splitLink
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first', // Use the existing setting you had before
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'cache-first', // Use the existing setting you had before
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all'
    }
  },
  assumeImmutableResults: true
});

// Reset store handler
apolloClient.onResetStore(async () => {
  try {
    await safeResetStore();
  } catch (error) {
    console.error('Store reset failed:', error);
    Sentry.captureException(error, {
      tags: { errorType: 'StoreResetError' }
    });
  }
});

// Vue Apollo provider
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  errorHandler(error: Error) {
    console.error('Global Apollo error handler:', error);
    Sentry.captureException(error, {
      tags: { errorType: 'ApolloGlobalError' },
      extra: {
        message: error.message,
        stack: error.stack
      }
    });
  }
});

export {
  apolloClient,
  apolloProvider
}