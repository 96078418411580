import { categoriesIconsMap } from '@/assets/img/shop/ecomIconsMap'
import { GetTrinityEComGetProductCatalog, GetTrinityEComGetProductCatalogQuery, GetTrinityEComGetProductCatalogQueryVariables, GetTrinityEComGetProductQuery, GetTrinityEComReferenceData, GetTrinityEComReferenceDataQuery, GetTrinityEComReferenceDataQueryVariables, SupportedCountries, TrinityEComCreateCheckout, TrinityEComCreateCheckoutMutation, TrinityEComCreateCheckoutMutationVariables, TrinityEComUpdateCheckoutDeliveryOption, TrinityEComUpdateCheckoutDeliveryOptionMutation, TrinityEComUpdateCheckoutDeliveryOptionMutationVariables, TrinityEComUpdateCheckoutDeliveryOptionsPostCodeMutation, TrinityEComUpdateCheckoutDeliveryOptionsPostCodeMutationVariables, TrinityEComUpdateCheckoutDeliveryOptionsPostCode, TrinityEComUpdateCheckoutPaymentTypeMutation, TrinityEComUpdateCheckoutPaymentTypeMutationVariables, TrinityEComUpdateCheckoutPaymentType, TrinityEComCreateCheckoutPayPalMutation, TrinityEComCreateCheckoutPayPalMutationVariables, TrinityEComCreateCheckoutPayPal, TrinityEComGetTermsAndConditionsQuery, TrinityEComGetTermsAndConditionsQueryVariables, TrinityEComGetTermsAndConditions } from '@/graphql'
import { apolloClient } from '@/plugins/apollo'
import { defineStore } from 'pinia'
import { Preferences } from '@capacitor/preferences'
import { useUserStore } from './user'

const ShopBarsketKey = 'ShopBarsket'

export enum CheckoutState {
  Overview = 'Overview',
  Delivery = 'Delivery',
  SelectPaymentType = 'SelectPaymentType',
  PayPalForm = 'PayPalForm',
  Payment = 'Payment'
}

export enum SupportedPaymentTypes {
  Klarna = 20,
  PayPal = 22
}

export enum TrinityEComSupportEmails {
  DK = 'mailto:dk@ledapshop.com',
  DE = 'mailto:de@ledapshop.com',
  AT = 'mailto:at@ledapshop.com'
}

interface ShoppingBagItem {
  product: GetTrinityEComGetProductQuery['trinityEComGetProduct']
  quantity: number
  sku: string
  sizeName?: string,
  deliverableStockValue: number
}

export interface DeliveryOption {
  id: string
  name: string
  title: string
  description: string
  sku: string
  agentId: string
  deliveryInterval: string
  price: number
  vatPercent: number
  imageUrl: string
  agents: DeliveryOptionAgent[]
}

interface DeliveryOptionAgent {
  id: string
  name: string
  address: Address
  mapLatitude: number
  mapLongitude: number
  phone: string
  estimatedDeliveryDate: string
}

interface Address {
  companyName: string
  recipient: string
  address1: string
  address2: string
  postCode: string
  city: string
  country: string
}

interface PaymentType {
  paymentTypeId: number
  vendor: number
  name: string
  description: string
  shortDescription: string
  logoImageUrl: string
  supportsZeroSum: boolean
}

export interface CustomerBillingAddressForm {
  firstName: string
  lastName: string
  address1: string
  postCode: string
  city: string
  country: string
  email: string
  phoneCountryCode: string
  phoneNumber: string
}

interface ShopeState {
  basicData: GetTrinityEComReferenceDataQuery['trinityEComReferenceData'] | null
  country: SupportedCountries, 
  selectedDeliveryOption: DeliveryOption | null,
  selectedAgent: DeliveryOptionAgent | null,
  selectedPaymentType: PaymentType | null,
  customerBillingAddressForm: CustomerBillingAddressForm | null,
  postCode: string | null,
  termsAndConditions: string | null,

  // * Current Category
  currentCategoryId: number | null
  currentCategoryLoading: boolean
  currentCategoryData: GetTrinityEComGetProductCatalogQuery['trinityEComGetProductCatalog'] | null
  currentCategoryQueryParameters: GetTrinityEComGetProductCatalogQueryVariables['parameters']

  // * Shopping bag
  shoppingBag: ShoppingBagItem[]

  // * Checkout
  checkoutState: CheckoutState
  checkoutIsLoading: boolean
  checkoutSessionData: TrinityEComCreateCheckoutMutation['trinityEComCreateCheckout'] | null
}

export const useShopStore = defineStore({
  id: 'Shop',

  state: (): ShopeState => ({
    basicData: null,
    country: SupportedCountries.Dk,
    selectedDeliveryOption: null,
    selectedAgent: null,
    selectedPaymentType: null,
    customerBillingAddressForm: null,
    postCode: null,
    termsAndConditions: null,

    // * Current Category
    currentCategoryId: null,
    currentCategoryLoading: false,
    currentCategoryData: null,
    currentCategoryQueryParameters: {
      page: 1,
      pageSize: 20
    },

    // * ShoppingBag
    shoppingBag: [],

    // * Checkout
    checkoutState: CheckoutState.Overview,
    checkoutIsLoading: false,
    checkoutSessionData: null
  }),

  getters: {
    currencyCode: state => state.basicData?.currencyCode || 'DKK',

    // * ShoppingBag
    shoppingBagSize(state) {
      let totalItems = 0
      state.shoppingBag.forEach(item => {
        totalItems += item.quantity
      })
      return totalItems
    },
    shoppingBagTotalPrice: state => state.shoppingBag.reduce((total, item) => total + (item.product.price * item.quantity), 0),
    isItemInShoppingCart: state => {
      return (sku: string) => state.shoppingBag.some(item => item.sku === sku)
    },

    // * Is post code valid
    postCodeIsValid: state => state.checkoutSessionData?.deliveryCheckoutServiceConfiguration.postCodeIsValid,

    // * Payment Options
    paymentTypes: state => state.checkoutSessionData?.paymentTypes || [],

    // * Payment Mode - if 0 one checkout available, if 1 multiple payments types are available
    paymentMode: state => state.checkoutSessionData?.paymentMode,

    // * Delivery Options
    deliveryOptions: state => state.checkoutSessionData?.deliveryCheckoutServiceConfiguration.options || [],
    
    // * Categories
    categories: state => state.basicData?.categories.map(category => ({
      ...category,
      //@ts-ignore
      icon: categoriesIconsMap[category.id]
    })) || [],

    // * Current Category
    currentCategoryProducts: state => state.currentCategoryData?.products || [],
    showNoProducts: state => state.currentCategoryData && !state.currentCategoryData.products.length,

    // * Checkout
    checkoutPaymentHtml: state => state.checkoutSessionData?.paymentServiceContainer!.embeddedCheckoutHtmlSnippet || null,
  },

  actions: {
    
    async init() {
      const User = useUserStore()
      this.country = User.user?.country!

      // Shop only needs to be initialized if it's available for the user
      if (User.user?.ecomAvailable) {
        const existingBarsket = await Preferences.get({ key: ShopBarsketKey })
        if (existingBarsket.value) {
          this.shoppingBag = JSON.parse(existingBarsket.value)
        }
  
        const referenceData = await apolloClient.query<GetTrinityEComReferenceDataQuery, GetTrinityEComReferenceDataQueryVariables>({
          query: GetTrinityEComReferenceData,
          variables: {
            country: this.country!
          }
        })
  
        this.basicData = referenceData.data.trinityEComReferenceData

        // If country is Germany or Austria - prefill PayPal form with User information
        if ([SupportedCountries.De, SupportedCountries.At].includes(this.country)) {
          const userInformation = await User.getUserInformation()
          this.customerBillingAddressForm = {
            firstName: userInformation.firstName,
            lastName: userInformation.lastName,
            address1: userInformation.streetName,
            postCode: userInformation.zipCode,
            city: userInformation.city,
            country: userInformation.country,
            email: userInformation.email,
            phoneCountryCode: userInformation.phoneCountryCode,
            phoneNumber: userInformation.phoneNumberWithoutCountryCode
          }
        }
  
        if (this.basicData.categories.length) {
          const initialCategoryId = this.basicData.categories[0].id
          await this.setCurrentCategory(initialCategoryId)
        }
      }
    },

    resetUserInput() {
      this.postCode = null
      this.selectedDeliveryOption = null
      this.selectedAgent = null
      this.selectedPaymentType = null
    },

    // * Current Category
    async setCurrentCategory(categoryId: number) {
      this.currentCategoryId = categoryId
      this.currentCategoryData = null
      this.currentCategoryQueryParameters.page = 1
      await this.loadCurrentCategory()
    },

    async loadCurrentCategory() {
      this.currentCategoryLoading = true
      try {
        const category = this.basicData?.categories.find((x) => x.id === this.currentCategoryId)
        if (!this.currentCategoryId) throw new Error('loadCurrentCategory - No current category id set.')
        if (!category) throw new Error(`Category with ID ${this.currentCategoryId} not found`)
    
        const products = await apolloClient.query<GetTrinityEComGetProductCatalogQuery, GetTrinityEComGetProductCatalogQueryVariables>({
          query: GetTrinityEComGetProductCatalog,
          variables: {
            category: this.currentCategoryId,
            categoryType: category.type!,
            parameters: this.currentCategoryQueryParameters,
            country: this.country!
          },
          errorPolicy: 'all' // This allows handling errors gracefully
        })
    
        // If there's data in the response, process it
        if (products.data && products.data.trinityEComGetProductCatalog) {
          const currentCategoryProducts = this.currentCategoryData?.products || []
          const newProducts = products.data.trinityEComGetProductCatalog.products || []
    
          this.currentCategoryData = {
            ...products.data.trinityEComGetProductCatalog,
            products: [
              ...currentCategoryProducts,
              ...newProducts
            ]
          }
        } else if (products.errors) {
          console.error('GraphQL errors:', products.errors)
          // Optionally display an error to the user
        }
      } catch (error) {
        console.error('Error loading category products:', error)
        // Handle error - perhaps set a flag to show error state in UI
      } finally {
        this.currentCategoryLoading = false
      }
    },

    currentCategoryLoadMore() {
      this.currentCategoryQueryParameters.page++
      this.loadCurrentCategory()
    },


    // * ShoppingBag
    addToShoppingBag(item: GetTrinityEComGetProductQuery['trinityEComGetProduct'], deliverableStockValue: number, sku: string, sizeName?: string): boolean {
      let added = false;
      // Check for existing item
      const existingItemIndex = this.shoppingBag.findIndex(i => i.sku === sku)
      if (existingItemIndex !== -1) {
        // Validate quantity is in stock
        if (this.shoppingBag[existingItemIndex].quantity === deliverableStockValue) {
          added = false
        } else {
          this.shoppingBag[existingItemIndex].quantity++
          this.saveShoppingBag()
          added = true
        }
      } else {
        this.shoppingBag.push({
          product: item,
          quantity: 1,
          sku,
          sizeName,
          deliverableStockValue
        })
        this.saveShoppingBag()
        added = true
      }
      return added
    },

    updateProductInShoppingBag(productId: number, quantity: number) {
      const productIndex = this.shoppingBag.findIndex(i => i.product.id === productId)
      this.shoppingBag[productIndex].quantity = quantity
    },

    removeFromBacket(sku: string) {
      this.shoppingBag = this.shoppingBag.filter(i => i.sku !== sku)
      this.saveShoppingBag()
    },

    async saveShoppingBag() {
      await Preferences.set({
        key: ShopBarsketKey,
        value: JSON.stringify(this.shoppingBag)
      })
    },

    clearShoppingBag() {
      this.shoppingBag.forEach((item) => {
        this.removeFromBacket(item.sku)
      })
    },

    // * Checkout
    async createCheckoutSession() {
      this.checkoutIsLoading = true
      try {
        const checkoutSession = await apolloClient.mutate<TrinityEComCreateCheckoutMutation, TrinityEComCreateCheckoutMutationVariables>({
          mutation: TrinityEComCreateCheckout,
          variables: {
            postCode: this.postCode!,
            cartItems: this.shoppingBag.map(item => ({
              quantity: item.quantity,
              sku: item.sku!,
            })),
            country: this.country!
          }
        })
        this.checkoutSessionData = checkoutSession.data!.trinityEComCreateCheckout
      } catch (error) {
        console.error('createCheckoutSession - Error', error)
      }

      this.checkoutIsLoading = false
    },
    
    async checkoutUpdateDeliveryOption() {
      this.checkoutIsLoading = true
      try {
        const response = await apolloClient.mutate<TrinityEComUpdateCheckoutDeliveryOptionMutation, TrinityEComUpdateCheckoutDeliveryOptionMutationVariables>({
          mutation: TrinityEComUpdateCheckoutDeliveryOption,
          variables: {
            token: this.checkoutSessionData?.shoppingCart.token!,
            selectedDeliveryOptionId: this.selectedDeliveryOption?.id!,
            selectedAgentId: this.selectedAgent?.id,
            country: this.country!,
            configurationId: this.checkoutSessionData?.deliveryCheckoutServiceConfiguration.id!,
            vendor: this.checkoutSessionData?.deliveryCheckoutVendor!,
          }
        })
        this.checkoutSessionData = response.data!.trinityEComUpdateCheckoutDeliveryOption
      } catch (error) {
        console.error('checkoutUpdateDeliveryOption - Error', error)
      }
      this.checkoutIsLoading = false
    },

    async checkoutUpdateDeliveryOptionsPostCode() {

      if (!this.postCode) return

      this.checkoutIsLoading = true
      this.selectedDeliveryOption = null
      this.selectedAgent = null

      try {
        const response = await apolloClient.mutate<TrinityEComUpdateCheckoutDeliveryOptionsPostCodeMutation, TrinityEComUpdateCheckoutDeliveryOptionsPostCodeMutationVariables>({
          mutation: TrinityEComUpdateCheckoutDeliveryOptionsPostCode,
          variables: {
            token: this.checkoutSessionData?.shoppingCart.token!,
            postCode: this.postCode!,
            country: this.country!,
            vendor: this.checkoutSessionData?.deliveryCheckoutVendor!,
          }
        })
        this.checkoutSessionData!.deliveryCheckoutServiceConfiguration = response.data!.trinityEComUpdateCheckoutDeliveryOptionsPostCode.deliveryCheckoutServiceConfiguration
      } catch (error) {
        console.error('checkoutUpdateDeliveryOptionsPostCode - Error', error)
      }
      this.checkoutIsLoading = false
    },

    async checkoutUpdatePaymentType() {
      this.checkoutIsLoading = true
      try {
        const response = await apolloClient.mutate<TrinityEComUpdateCheckoutPaymentTypeMutation, TrinityEComUpdateCheckoutPaymentTypeMutationVariables>({
          mutation: TrinityEComUpdateCheckoutPaymentType,
          variables: {
            token: this.checkoutSessionData?.shoppingCart.token!,
            country: this.country!,
            paymentTypeId: this.selectedPaymentType?.paymentTypeId!
          }
        })
        this.checkoutSessionData = response.data?.trinityEComUpdateCheckoutPaymentType!
      } catch (error) {
        console.error('checkoutUpdatePaymentType - Error', error)
      }
      this.checkoutIsLoading = false
    },

    async createCheckoutSessionPayPal() {
      this.checkoutIsLoading = true
      try {
        const response = await apolloClient.mutate<TrinityEComCreateCheckoutPayPalMutation, TrinityEComCreateCheckoutPayPalMutationVariables>({
          mutation: TrinityEComCreateCheckoutPayPal,
          variables: {
            token: this.checkoutSessionData?.shoppingCart.token!,
            country: this.country,
            customerEmail: this.customerBillingAddressForm?.email!,
            customerTelephone: this.customerBillingAddressForm?.phoneCountryCode! + this.customerBillingAddressForm?.phoneNumber!,
            customerBillingAddress: {
              recipient: `${this.customerBillingAddressForm?.firstName} ${this.customerBillingAddressForm?.lastName}`,
              address1: this.customerBillingAddressForm?.address1,
              postCode: this.customerBillingAddressForm?.postCode,
              city: this.customerBillingAddressForm?.city,
              country: this.customerBillingAddressForm?.country
            }
          }
        })
        this.checkoutSessionData = response.data?.trinityEComCreateCheckoutPayPal!
      } catch (error) {
        console.error('createCheckoutSessionPayPal - Error', error)
      }
      this.checkoutIsLoading = false
    },

    async getTermsAndConditions() {
      try {
        const response = await apolloClient.query<TrinityEComGetTermsAndConditionsQuery, TrinityEComGetTermsAndConditionsQueryVariables>({
          query: TrinityEComGetTermsAndConditions,
          variables: {
            country: this.country!
          }
        })
        this.termsAndConditions = response.data.trinityEComGetTermsAndConditions.bodyHtml!
      } catch (error) {
        console.error('getTermsAndConditions - Error', error)
      }
    },

    resetCheckout() {
      this.checkoutSessionData = null
      this.checkoutState = CheckoutState.Overview
    }
  }
})
