import { CustomRouteConfig } from "./";

const routes: CustomRouteConfig[] = [
	{
		name: "BookliScreen",
		path: "/screen/:id",
		component: () => import("@/views/other/bookliScreen.vue"),
		meta: {
			title: "Skærm",
			layout: "FullPage",
		},
	},
	{
		name: "BookliScreenV2",
		path: "/screen-v2/:id",
		component: () => import("@/views/other/bookliScreenV2.vue"),
		meta: {
			title: "Skærm",
			layout: "FullPage",
		},
	},
	{
		name: "BookliScreenV3",
		path: "/screen-v3/:id",
		component: () => import("@/views/other/bookliScreenV3.vue"),
		meta: {
			title: "Skærm",
			layout: "FullPage",
		},
	},

	{
		name: "ResourceBookingToCalendar",
		path: "/resource-booking/calendar/:id",
		component: () => import("@/views/other/resourceBookingToCalendar.vue"),
		meta: {
			title: "Booking",
			layout: "FullPage",
		},
	},

	{
		name: "WorkshopBookingToCalendar",
		path: "/workshop-booking/calendar/:id",
		component: () => import("@/views/other/workshopBookingToCalendar.vue"),
		meta: {
			title: "Booking",
			layout: "FullPage",
		},
	},

	{
		name: "Front",
		path: "/front",
		component: () => import("@/views/other/front.vue"),
		meta: {
			title: "Front",
			layout: "FullPage",
		},
	},

	{
		name: "FrontAttachments",
		path: "/front/:folder/attachments/:attachmentId",
		component: () => import("@/views/other/frontAttachments.vue"),
		meta: {
			title: "Front",
			layout: "FullPage",
		},
	},

	{
		name: "GoToUser",
		path: "/go/user/:idOrEmail",
		component: () => import("@/views/other/goToUser.vue"),
		meta: {
			title: "Go",
			layout: "FullPage",
		},
	},

	{
		name: "LocationsResourcesOverview",
		path: "/locations-overview",
		component: () => import("@/views/other/locationsResourcesOverview.vue"),
		meta: {
			title: "Oversigt",
			layout: "FullPage",
		},
	},

	{
		name: "BookliVideo",
		path: "/video/:id",
		component: () => import("@/views/other/bookliVideo.vue"),
		meta: {
			title: "Video",
			layout: "FullPage",
		},
	},

	{
		name: "BookliSound",
		path: "/sound/:id",
		component: () => import("@/views/other/bookliSound.vue"),
		meta: {
			title: "Lyd",
			layout: "FullPage",
		},
	},

	{
		name: "UnderMaintenance",
		path: "/under-maintenance",
		component: () => import("@/views/other/underMaintenance.vue"),
		meta: {
			title: "Bookli",
			layout: "FullPage",
		},
	},

	{
		name: "BookliEmbed",
		path: "/embed",
		component: () => import("@/views/other/bookliEmbed.vue"),
		meta: {
			title: "Embed",
			layout: "FullPage",
		},
	},

	{
		name: "BookliDevice",
		path: "/bookli-device",
		component: () => import("@/views/other/bookliDevice.vue"),
		meta: {
			title: "Bookli",
			layout: "FullPage",
		},
	},

	{
		name: "ResourceBookingsTimeline",
		path: "/resource-bookings-timeline",
		component: () => import("@/views/other/resourceBookingsTimelineEmbed.vue"),
		meta: {
			title: "Bookli",
			layout: "FullPage",
		},
	},
	{
		// This is the page Stripe will return to when a redirect-based payment has been done or cancelled
		name: "StripePaymentFinished",
		path: "/ext-payment-redirect",
		component: () => import("@/views/other/stripePaymentFinished.vue"),
		meta: {
			title: "Bookli",
			layout: "FullPage",
		},
	},
	{
		name: "RanglisteLogin",
		path: "/padelpadelrangliste",
		component: () => import("@/views/other/ranglistenLogin.vue"),
		meta: {
			title: "Bookli",
			layout: "FullPage",
		},
	},
	{
		name: "RanglistenLogin",
		path: "/padelpadelranglisten",
		component: () => import("@/views/other/ranglistenLogin.vue"),
		meta: {
			title: "Bookli",
			layout: "FullPage",
		},
	},
	{
		name: "PrivacyPolicy",
		path: "/privacypolicy",
		component: () => import("@/views/other/privacyPolicy.vue"),
		meta: {
			title: "Bookli Privacy Policy",
			layout: "FullPage",
		},
	},
	{
		name: "AdditionalServices",
		path: "/c/settings/additional-services",
		component: () => import("@/views/company/settings/additionalServices.vue"),
		meta: {
			title: "Additional Services",
			layout: "Admin",
		},
	}
];

export default routes;
