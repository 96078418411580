export default {
  subTitle: 'User',
  isDeleted: 'This user has been deleted',
  infoTitle: 'Informations',
  infoCards: {
    paymentsSum: 'Payment sum',
    resourceBookingsCount: 'Bookings in total',
    pointsAmount: 'Points',
    showPointAccount: 'View point account'
  },
  bookingRatio: {
    title: 'Booking ratio',
    infoHover: 'The figures below tell the number of bookings per. week.',
    resourceBookingRatioChartShow: {
      one: 'All life',
      two: 'Last 2 months',
      three: 'Last 4 months'
    },
    pastWeekRatio: 'Last 7 days',
    averageRatio: 'Avg.'
  },
  map: {
    title: 'User location',
    subTitle: '(Random location within a radius of 2 km.)'
  },
  info: {
    userSignInMethod: 'Sign in method',
    accessCode: 'Door access code',
    businessName: 'Company name',
    vatNumber: 'VAT number'
  },
  actions: {
    addOrRemovePoints: 'Add / remove points',
    stripeCustomer: 'View user in Stripe',
    sendUserPasswordResetEmail: 'Send password reset mail',
    deleteUser: 'Delete user',
    // Add these new translations
    addToBlockList: 'Block SMS to this user',
    removeFromBlockList: 'Unblock SMS to this user'
  },
  // Add the entire blockListModal section
  blockListModal: {
    addTitle: 'Block SMS to this user',
    removeTitle: 'Unblock SMS to this user',
    reasonLabel: 'Reason (optional)',
    reasonPlaceholder: 'Enter a reason for blocking this user from receiving SMS',
    blockButton: 'Block SMS to this number',
    unblockButton: 'Unblock SMS to this number',
    removeConfirmation: 'Are you sure you want to unblock this phone number? It will start receiving SMS again.',
    blockedReason: 'Current block reason',
    addSuccessToast: 'Phone number has been blocked from receiving SMS',
    removeSuccessToast: 'Phone number has been unblocked and will receive SMS again',
    noPhoneError: 'This user doesn\'t have a phone number',
    checkErrorToast: 'Error checking if the phone number is blocked',
    actionErrorToast: 'Error updating phone number block status'
  },
  userGroup: {
    title: 'User groups',
    addUserGroup: 'Add',
    none: {
      title: 'No user groups',
      text: 'This user is not a member of any groups.'
    }
  },
  recurringBookings: {
    title: 'Recurring bookings',
    none: {
      title: 'No recurring bookings',
      text: 'This user is not registered for any recurring bookings.'
    }
  },
  subscriptions: {
    title: 'Memberships',
    add: 'Add',
    none: {
      title: 'No memberships',
      text: 'This user is not a member of any memberships.'
    }
  },
  userPlatformConnections: {
    title: 'Connected platforms',
    signIn: 'Sign in'
  },
  bookingTab: {
    title: 'Bookings',
    resourceBookingsCount: 'Bookings in total',
    futureResourceBookingsCount: 'Future bookings'
  },
  paymentsTab: {
    title: 'Payments',
    paymentsSumChart: 'Revenue per week',
    paymentsSum: 'Payments sum',
    paymentsSumPeriod: 'This week'
  },
  updateUserModal: {
    title: 'Update user information'
  },
  createSubscriptionModal: {
    title: 'Add membership',
    selectSubscriptionProduct: {
      label: 'Choose a membership',
      placeholder: 'Select a membership...'
    },
    addEndDate: 'Add end date',
    endDatePladeholder: 'End dage for membership',
    addSubscriptionButton: 'Add membership',
    successToast: 'The membership has been created',
    shouldBePaidSubscription: 'User should pay for subscription with saved card (if off the subscription will be free)'
  },
  addUserToGroupModal: {
    title: 'Add user to user group',
    addToUserGroupButton: 'Add to group',
    addToUserGroupLoadingButton: 'Adding to group...',
    successToast: 'The user has been added'
  },
  removeUserFromGroupSuccessToast: 'The user is deleted',
  deleteUser: {
    sure: {
      text: 'Are you sure about this? When you delete a user, the person will be deleted across the platform and the person will not be able to log in again. This feature is not always functional - will be improved in the future',
      title: 'Deleting user',
      yes: 'Delete user'
    },
    withErrorToast: 'The user could not be deleted',
    successToast: 'The user is delete'
  },
  userPasswordReset: {
    successToast: {
      title: 'Email is sent',
      text: 'Success'
    }
  }
}