<template lang="pug">
  b-overlay(
    :show="eventsLoading"
    variant="white"
    spinner-variant="primary"
    rounded="lg")
  
    b-card.mb-0(body-class="p-0 overflow-hidden")
      div.flex.gap-x-2.gap-y-1.flex-wrap.justify-center.my-3(v-if="!isPublicLocal")
        div.flex.items-center.gap-1(v-for="reservationType in reservationTypesInfo")
          div.rounded-full.w-2.h-2(:style="{ backgroundColor: reservationType.color }")
          small {{ reservationType.text }}
  
  
      div.flex.justify-between.items-center.mx-4.my-3.flex-wrap.gap-y-3
        div
          h2.m-0 {{ calendarDate | moment('dddd') }} - {{ calendarDate | moment('D. MMM YYYY') }}
  
        div.flex.gap-3
          b-button-group
            b-button(
              :disabled="!canGoPrevDay"
              @click="goPrevDay()"
              variant="primary")
              .fe.fe-chevron-left
            b-button(
              :disabled="!canGoNextDay"
              @click="goNextDay()"
              variant="primary")
              .fe.fe-chevron-right
  
          b-button.d-block(
            id="popover-target-1"
            variant="white")
            .d-flex.align-items-center
              i.fe.fe-chevron-down.mr-2
              span {{ $t('components.admin.resourceBookings.timeline.chooseDate') }}
  
          b-popover(
            custom-class="popover-big p-0"
            target="popover-target-1"
            triggers="hover"
            placement="leftbottom")
            v-date-picker.vc-no-border(
              mode="single"
              is-inline
              :min-date="pickerMinDate"
              :max-date="pickerMaxDate"
              v-model="selectedDate")
  
      b-col.flex.my-3.flex-wrap(v-if="resourceCategories.length > 1")
        b-row
          b-form-group(
            :label="$t('components.admin.resourceBookings.timeline.resourceCategoryLabel')"
            v-if="resourceCategories.length > 1"
            ).mx-4.my-2
        b-row
          b-form-select(v-model="selectedResourceCategoryId").mx-2
            b-form-select-option(v-for="(option, index) in resourceCategoriesOptions" :key="index" :value="option.value") {{ option.text }}
  
      FullCalendar.fc-full-width-padding(
        ref="fullCalendar"
        :options="calendarOptions")
        template(v-slot:resourceLabelContent='arg')
          .resource-wrapper(:style="{ color: arg.resource.extendedProps.isPrice ? '#95AAC9' : '' }")
            .logo-container(v-if="arg.resource.extendedProps.logoUrl" v-bind:style="{backgroundImage: `url(${arg.resource.extendedProps.logoUrl})`}")
            span(v-if="useSmallLayout && !arg.resource.extendedProps.logoUrl") {{ arg.resource.title }} 
            span.resource-text(v-if="!useSmallLayout") {{ arg.resource.title }} 
  
      div(v-if="allowPickingReservedTimeInternal")
        b-alert.m-3(v-model="reservedTimeData.start ? true : false" variant="light")
          b-row(align-v="center" v-if="reservedTimeData.start")
            b-col
              p.m-0
                span {{ $t('components.admin.resourceBookings.timeline.createReservedTime.text1') }} &nbsp;
                b {{ reservedTimeData.resource.name }} &nbsp;
                span - {{ $t('components.admin.resourceBookings.timeline.createReservedTime.text2') }} &nbsp;
                b {{ reservedTimeData.start | moment('HH:mm') }} &nbsp;
                span {{ $t('components.admin.resourceBookings.timeline.createReservedTime.text3') }} &nbsp;
                b  {{ reservedTimeData.end | moment('HH:mm') }}
  
            b-col(cols="auto")
              b-button.mr-3(size="sm" variant="outline-danger" @click="resetPickReservedTime()") {{ $t('common.cancel') }}
              b-button(size="sm" variant="primary" @click="createReseverdTime()") {{ $t('components.admin.resourceBookings.timeline.createReservedTime.createReservedTime') }}
  
        CreateReservedTime(ref="create-reserved-time" @createdReservedTime="updateEvents()")
  
  
    </template>
  
  <script lang="ts">
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { GetResourcesLocationTimeline, GetResourcesLocationTimelineQueryVariables, GetResourcesLocationTimelineQuery, UserRole, GetResourceCategories, GetResourceCategoriesQuery } from '@/graphql'
  import '@fullcalendar/core'
  import FullCalendar from '@fullcalendar/vue'
  import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'
  import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
  import { LocaleType } from '@/store/modules/locale';
  import { useUserStore } from '@/store/modules/user'
  import { gql } from '@apollo/client/core'
  import { CalendarApi, CalendarOptions, DatesSetArg, EventClickArg } from '@fullcalendar/core'
  import { setLocaleInput } from '@/locales/setupI18n'
  
  @Component({
    name: 'ResourcesBookingTimeline',
    components: {
      FullCalendar,
      'CreateReservedTime': () => import('@/components/admin/resourceBookings/createReservedTime.vue')
    },
    apollo: {
    }
  })
  export default class TimelineResourceBooking extends Vue {
    @Prop({ required: false }) private locationId!: string
    @Prop({ required: false, default: true }) private allowPickingReservedTime!: boolean
    @Prop({ required: false, default: null }) private isPublic!: boolean
    @Prop({ required: false, default: true }) private showPrices!: boolean
  
    userStore = useUserStore()
  
    locationIdLocal: string | null = null
    selectedDate = new Date()
    resourceAreaWidthDefualt = '300px'
    resourceAreaWidthSmall = '110px'
    useSmallLayout = false
    eventsLoading = false
    reservationTypeColors: { [key: string]: string } = {}
    latestDateForBooking = new Date()
    calendarDate = new Date()
    selectedResourceCategoryId: string | null = null
    resourceCategories: GetResourceCategoriesQuery['resourceCategories']['data'] = []
    isGettingData = false
  
    reservedTimeData: { start: null | string, end: null | string, resource: { id: string, name: string } | null } = {
      start: null,
      end: null,
      resource: null
    }
  
    get resourceCategoriesOptions(): { text: string, value: string }[] {
      return this.resourceCategories.map(category => ({
        text: category.name,
        value: category.id
      }));
    }
  
    created() {
      this.selectedResourceCategoryId = null
      if (this.locationId) this.locationIdLocal = this.locationId
      this.calendarOptions.locales = setLocaleInput(this.$i18n.locale)
      this.windowResize()
    }
  
  
    @Watch('selectedResourceCategoryId')
    selectedResourceCategoryChange() {
      this.getData()
    }
  
    @Watch('selectedDate')
    selectedDateChange() {
      this.fullCalendarApi().gotoDate(this.selectedDate)
    }
    @Watch('$i18n.locale', { immediate: true })
    calendarLocale(newLocale: LocaleType) {
      this.calendarOptions.locales = setLocaleInput(newLocale)
    }
  
    get isPublicLocal() {
      return typeof this.isPublic === 'boolean' ? this.isPublic : this.userStore.user?.role !== UserRole.Company
    }
  
    get canGoPrevDay() {
      if (!this.isPublicLocal) return true
      const a = this.$moment(this.calendarDate).subtract(1, 'day').endOf('day').toDate()
      const b = this.$moment().startOf('day').toDate()
      if (a < b) return false
      return true
    }
  
    get canGoNextDay() {
      if (!this.isPublicLocal) return true
      const a = this.$moment(this.calendarDate).endOf('day').toDate()
      const b = this.$moment(this.latestDateForBooking).startOf('day').toDate()
      if (a > b) return false
      return true
    }
  
    get allowPickingReservedTimeInternal() {
      if (!this.isPublicLocal && this.allowPickingReservedTime) return true
      return false
    }
  
    get pickerMinDate() {
      if (!this.isPublicLocal) return undefined
      return new Date()
    }
  
    get pickerMaxDate() {
      if (!this.isPublicLocal) return undefined
      return this.latestDateForBooking
    }
  
    get reservationTypesInfo(): { color: string, text: string }[] {
      return Object.entries(this.reservationTypeColors).map(([key, val]) => ({
        color: val,
        text: this.$t(`common.reservationType.${key}`) as string
      }))
    }
  
    fullCalendarApi() {
      // @ts-ignore
      return this.$refs['fullCalendar'].getApi() as CalendarApi
    }
  
    goNextDay() {
      this.fullCalendarApi().next()
    }
  
    goPrevDay() {
      this.fullCalendarApi().prev()
    }
  
  
    
    windowResize() {
      this.useSmallLayout = window.innerWidth < 550
      this.calendarOptions.resourceAreaWidth = this.useSmallLayout ? this.resourceAreaWidthSmall : this.resourceAreaWidthDefualt
    }
  
    updateEvents() {
      this.getData()
    }
  
    async getLocationId() {
      try {
        const getLocationQuery = await this.$apollo.query({
          query: gql`
          query GetMeLocation {
            me {
              location {
                id
              }
            }
          }
          `,
          fetchPolicy: 'network-only'
        })
  
        this.locationIdLocal = getLocationQuery.data.me.location.id
      } catch (error) {
        console.error('Error fetching location ID:', error)
        throw error
      }
    }
  
    async getSelectedResourceCategory() {
      try {
        const { data: { resourceCategories } } = await this.$apollo.query<GetResourceCategoriesQuery>({
          query: GetResourceCategories,
          variables: {
            isPublic: this.isPublicLocal,
            locationId: this.locationIdLocal
          }
        })
  
        this.resourceCategories = resourceCategories.data
  
        if (resourceCategories.data.length > 0) {
          this.selectedResourceCategoryId = resourceCategories.data[0].id
        } else {
          throw new Error('No resource categories available')
        }
      } catch (error) {
        console.error('Error fetching resource categories:', error)
        throw error
      }
    }
  
    // Modified getData method with retry logic and error handling
    async getData(retry = 0) {
      // Prevent concurrent getData calls
      if (this.isGettingData) {
        console.log('getData already in progress, skipping')
        return
      }
      
      this.isGettingData = true
      const maxRetries = 2
      
      try {
        if (!this.locationIdLocal) await this.getLocationId()
        if (!this.locationIdLocal) throw new Error('missing locationIdLocal')
        if (!this.selectedResourceCategoryId) await this.getSelectedResourceCategory()
        if (!this.selectedResourceCategoryId) throw new Error('missing selectedResourceCategory')
  
        this.eventsLoading = true
  
        // Create timeout promise to avoid hanging requests
        const timeoutPromise = new Promise((_, reject) => 
          setTimeout(() => reject(new Error('Request timeout')), 30000)
        )
  
        // Setup the GraphQL query with error handling
        const queryPromise = this.$apollo.query<GetResourcesLocationTimelineQuery, GetResourcesLocationTimelineQueryVariables>({
          query: GetResourcesLocationTimeline,
          variables: {
            resourceCategory: this.selectedResourceCategoryId!,
            date: this.calendarDate,
            location: this.locationIdLocal!,
            isPublic: this.isPublicLocal
          },
          fetchPolicy: 'network-only', // Always fetch fresh data
          errorPolicy: 'all',
          skip() {
            return !this.locationIdLocal || !this.selectedResourceCategoryId
          }
        })
  
        // Race between the query and timeout
        const result = await Promise.race([
          queryPromise,
          timeoutPromise
        ])
  
        const { data: { resourcesLocationTimeline } } = result
  
        // Process the rest of the data as in original code
        const bookingsMade = resourcesLocationTimeline.resourceBookings
        const bookingsMadeSorted = [...resourcesLocationTimeline.resourceBookings]
  
        //@ts-ignore
        let slotMinTime = this.$moment(resourcesLocationTimeline.openTimeUtc, 'HH:mm', 'UTC').local().format('HH:mm')
        //@ts-ignore
        let slotMaxTime = this.$moment(resourcesLocationTimeline.closeTimeUtc, 'HH:mm', 'UTC').local().format('HH:mm')
  
        if (bookingsMade.length > 0) {
          bookingsMadeSorted.sort((a: { startDate: string | number | Date }, b: { startDate: string | number | Date }) => {
            return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
          })
  
          const earliestBookingTime = new Date(bookingsMadeSorted[0].startDate)
          const latestBookingTime = new Date(bookingsMadeSorted[bookingsMadeSorted.length - 1].endDate)
  
          const openTimeHours = parseInt(resourcesLocationTimeline.openTimeUtc.split(":")[0], 10)
          const closeTimeHours = parseInt(resourcesLocationTimeline.closeTimeUtc.split(":")[0], 10)
  
          if(earliestBookingTime.getHours() <= openTimeHours){
            slotMinTime = this.$moment(earliestBookingTime).startOf('hour').subtract(0, 'minutes').local().format('HH:mm')
          }
  
          if(closeTimeHours !== 0 && latestBookingTime.getHours() >= closeTimeHours) {
            slotMaxTime = this.$moment(latestBookingTime).startOf('hour').add(60, 'minutes').local().format('HH:mm')
          }
        }
  
        if (slotMaxTime === '00:00') slotMaxTime = '23:59'
        else if (slotMaxTime === '01:00') slotMaxTime = '23:59'
  
        this.reservationTypeColors = JSON.parse(resourcesLocationTimeline.reservationTypeColors)
        this.latestDateForBooking = resourcesLocationTimeline.latestDateForBooking
        this.calendarOptions.slotMinTime = slotMinTime
        this.calendarOptions.slotMaxTime = slotMaxTime
        this.calendarOptions.selectable = this.allowPickingReservedTimeInternal
  
        this.calendarOptions.resources! = resourcesLocationTimeline.resources.map((resource: any) => {
          const resourceType = resourcesLocationTimeline.resourceTypes.find((x: { id: string }) => x.id === resource.typeId)!
          return {
            id: resource.id,
            resourceType: resourceType.name,
            logoUrl: resource.logoUrl,
            title: resource.name,
            popularity: resource.popularity
          }
        })
  
        this.calendarOptions.events! = resourcesLocationTimeline.resourceBookings.map((resourceBooking: any) => {
          return {
            id: resourceBooking.id,
            start: resourceBooking.startDate,
            end: resourceBooking.endDate,
            resourceId: resourceBooking.resourceId,
            title: resourceBooking.description,
            backgroundColor: resourceBooking.color,
            borderColor: resourceBooking.color,
          }
        })
  
        if (this.showPrices) {
          resourcesLocationTimeline.resourceTypes.forEach((resourceType: any) => {
            //@ts-ignore
            this.calendarOptions.resources!.push({
              id: resourceType.id,
              title: this.$i18n.t('components.admin.resourceBookings.timeline.pricePerHourLabel'),
              resourceType: resourceType.name,
              popularity: 0,
              isPrice: true
            })
            
            resourceType.pricesForDate.forEach((priceForDate: any, index: number) => {
              const color = index % 2 ? '#EDF2F9' : '#e3ebf7'
              //@ts-ignore
              this.calendarOptions.events!.push({
                id: `${resourceType.name}-${new Date(priceForDate.startDate).getTime()}_${new Date(priceForDate.endDate).getTime()}`,
                start: priceForDate.startDate,
                end: priceForDate.endDate,
                resourceId: resourceType.id,
                title: `${priceForDate.pricePerHourString} (${priceForDate.priceType})`,
                backgroundColor: color,
                borderColor: color,
                textColor: '#283E59'
              })
            })
          })
        }
      } catch (error) {
        console.error('Error fetching timeline data:', error)
        
        // If we have retries left, try again with exponential backoff
        if (retry < maxRetries) {
          const backoffDelay = Math.pow(2, retry) * 1000 // Exponential backoff: 1s, 2s, 4s
          console.log(`Retrying getData in ${backoffDelay}ms (attempt ${retry + 1}/${maxRetries})`)
          
          // Wait before retrying
          await new Promise(resolve => setTimeout(resolve, backoffDelay))
          
          // Release lock before retry
          this.isGettingData = false
          this.getData(retry + 1)
          return
        } else {
          // After max retries, show error to user
          this.$bvToast?.toast('Could not load booking data. Please try refreshing the page.', {
            title: 'Loading Error',
            variant: 'danger',
            solid: true
          })
        }
      } finally {
        this.eventsLoading = false
        this.isGettingData = false
      }
    }
  
    setCalendarDate(date: DatesSetArg) {
      const newDate = this.$moment(date.start).add(6, 'hours').toDate()
      const hasChanged = this.calendarDate.getTime() !== newDate.getTime()
  
      if (hasChanged) {
        this.calendarDate = newDate
        this.$emit('dateChange', this.calendarDate)
        this.getData()
      }
    }
  
    calendarOptions: CalendarOptions = {
      plugins: [
        interactionPlugin,
        resourceTimelinePlugin
      ],
      slotDuration: '00:30',
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      events: [],
      initialView: 'resourceTimelineDay',
      slotMinTime: '00:00:00',
      slotMaxTime: '24:00:00',
      resourceOrder: 'popularity',
      locales: [],
      nowIndicator: true,
      eventClick: this.calendarEventClick,
      dateClick: this.calendarDateClick,
      height: 'auto',
      resources: [],
      resourceGroupField: 'resourceType',
      headerToolbar: false,
      resourceAreaHeaderContent: ' ',
      selectable: false,
      resourceGroupLaneContent: (event) => {
        // https://fullcalendar.io/docs/resource-group-render-hooks
      },
      select: ($event) => this.setReservedTimeDate({
        start: $event.start,
        end: $event.end,
        resource: {
          id: $event.resource!.id,
          name: $event.resource!.title,
          isPrice: $event.resource!.extendedProps.isPrice
        }
      }),
      datesSet: (dateInfo) => this.setCalendarDate(dateInfo),
      // eventsSet: (e) => this.$emit('eventsSet')
    }
  
    // * Calender events
    calendarEventClick(info: EventClickArg) {
      if (info.event.id === 'null') return
      if (this.userStore.user?.role !== UserRole.Company) return
  
      this.$emit('eventClicked', info)
  
      this.$root.$emit('showThing', {
        id: info.event.id,
        type: 'ResourceBooking'
      })
    }
  
    calendarDateClick(info: DateClickArg) {
      this.$emit('eventClicked', info)
    }
  
    createReseverdTime() {
      // @ts-ignore
      this.$refs['create-reserved-time'].show({
        startDate: this.reservedTimeData.start,
        endDate: this.reservedTimeData.end,
        resourceId: this.reservedTimeData.resource!.id
      })
      this.resetPickReservedTime()
    }
  
    resetPickReservedTime() {
      this.reservedTimeData = {
        start: null,
        end: null,
        resource: null
      }
    }
  
    setReservedTimeDate(data: any) {
      if (!this.allowPickingReservedTimeInternal) return
      if (data.resource.isPrice) return
  
      this.reservedTimeData = {
        start: data.start,
        end: data.end,
        resource: {
          id: data.resource.id,
          name: data.resource.name
        }
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .resource-text {
    text-wrap: balance;
  }
  </style>