import { Vue } from 'vue-property-decorator'

interface SmartMutationInput {
  doneToast?: {
    title?: string
    text?: string
    i18nString?: string
  },
  componentThis: Vue | null
  hideModalName?: string
  refetchQueryName?: string
  mutation: any
  variables?: Object
  refetchQueryGraphQlListRef?: string
  doneFunction?(data: any): any
  beforeFunction?: Function
}

export class SmartMutation {
  isLoading = false
  formDisabled = false
  componentThis: Vue | null

  private variablesInit?: Object

  variables?: Object

  doneToast?: {
    title?: string
    text?: string
    i18nString?: string
  }

  doneFunction?: Function
  beforeFunction?: Function
  mutation: any
  refetchQueryName?: string
  hideModalName?: string
  refetchQueryGraphQlListRef?: string

  constructor (input: SmartMutationInput) {
    this.refetchQueryName = input.refetchQueryName
    this.variables = typeof input.variables === 'function' ? input.variables() : input.variables
    this.variablesInit = this.variables
    this.doneFunction = input.doneFunction
    this.mutation = input.mutation
    this.componentThis = input.componentThis
    this.refetchQueryName = input.refetchQueryName
    this.hideModalName = input.hideModalName
    this.doneToast = input.doneToast
    this.beforeFunction = input.beforeFunction
    this.refetchQueryGraphQlListRef = input.refetchQueryGraphQlListRef
  }

  initData () {
    this.variables = this.variablesInit
    this.isLoading = false
    this.formDisabled = false
  }

  async mutate (variables?: any) {
    if (variables) this.variables = variables
    if (!this.componentThis) {
      console.log('Missing this')
      return
    }
    this.isLoading = true
    this.formDisabled = true

    if (this.beforeFunction) {
      try {
        await this.beforeFunction(this)
      } catch (error) {
        console.log('smart mutation error', error)
        this.initData()
        throw new Error(error as any)
      }
    }

    const mutation = await this.componentThis.$apollo.mutate({
      mutation: this.mutation,
      variables: this.variables,
      errorPolicy: 'all'
    })

    if (mutation.errors) {
      this.componentThis.$bvToast.toast(mutation.errors[0].message, {
        title: 'Error...',
        variant: 'danger'
      })
      this.initData()
      return
    }

    if (this.hideModalName) {
      // @ts-ignore
		  this.componentThis.$refs[this.hideModalName].hide()
    }

    if (this.refetchQueryName) {
      this.componentThis.$apollo.queries[this.refetchQueryName].refetch()
    }

    if (this.refetchQueryGraphQlListRef) {
      // @ts-ignore
      this.componentThis.$refs.refetchQueryGraphQlListRef.refetchQuery()
    }

    if (this.doneToast) {
      this.componentThis.$bvToast.toast(this.doneToast.i18nString
          ? this.componentThis.$i18n.t(this.doneToast.i18nString) as string
          : this.doneToast.text!,
      {
        title: this.doneToast.title || 'Bookli', 
        variant: 'success'
      })
    }

    if (this.doneFunction) {
      try {
        await this.doneFunction(mutation.data)
      } catch (error) {
        console.log('smart mutation error', error)
        this.initData()
        throw new Error(error as any)
      }
    }

    this.initData()

    return mutation.data
  }
}
