export default {
    subTitle: "Settings",
    pageTitle: "Migration",
    subSubTitle: "Migration of users",
    stepOne: "Step 1:",
    stepTwo: "Step 2:",
    stepThree: "Step 3:",
    explanationStepOne: "Write a message to your users that will be used in the email.",
    placeholderMessage: "Write message here...",
    explanationStepTwo: "Upload file with user data.",
    explanationStepThree: "Once you have completed above steps, click button below to send migration email to your users.",
    optionalStep: "Optional step: Send test email",
    explanationOptionalStep: "This is an optional step where you can send a test email to the email you enter in the field below. By performing this step, you can ensure that the email looks as you wish before sending it to your users.",
    description: "Here, you can provide your users with an easier way to migrate from your previous platform to Bookli! <br> This is done by writing a message to your users, uploading a Excel file with all of your user data, and clicking 'Send'. The users will then receive an email with your message and a link to a sign up form, where their data has been prefilled.",
    sendButton: "Send migration emails to users",
    sendTestEmail: "Send test email",
    uploadButton: "Upload file",
    processing: "Processing...",
    validColumnDescription: "The file needs to include at least one of the columns listed below. The emails are used for sending out the invitation emails, so make sure they are updated and present in the file.",
    validColumns: "firstName (the first name of the customer), lastName (the last name of the customer), email, phoneNumber (the phone number of the customer. Exclude country code in the phone number.), streetName (the street name of the customer), zipCode (the zip code of the customer), city, country (country of the user. Enter 'DK' for Denmark. 'DE' for Germany. 'AU' for Austria. Etc.)",
    errorFileUpload: "You must upload a file in order to send a email!",
    errorMessageContent: "You must write a message in order to send a email!",
    errorTestSendOut: "You must enter at least one valid recipient email in order to send a test email!"
}