export default {

  title: 'Your settings',
  
  showUsedGiftCard: {
    text: 'You have received {0} points'
  },

  friends: {
    title: 'Friends',
    description: 'Add teammates and opponents.'
  },

  ecomHistory: {
    title: 'Bookli Shop order history'
  },
  
  giftCard: {
    title: 'Redeem or buy gift cards',
    description: 'Here you can redeem a gift card or read more about how to buy one for a good friend.',

    buyGiftCard: {
      title: 'Buy gift card',
      description: 'To purchase a gift card, contact the location where you would like the gift card to be valid.'
    },

    redeemGiftCard: {
      title: 'Redeem gift card',
      description: 'Enter the code on your gift card below and get the points added to your user.',
      inputPlaceholder: 'Enter the provided code',

      redeemGiftCardButton: 'Redeem gift card',
      redeemGiftCardLoadingButton: 'Redeeming gift cards...'
    }
  },

  connections: {
    title: 'Connected padel locations',
    description: 'Modify or add your user to other companies and / or locations',

    createNewButton: 'Create a new connection',
    createNewModal: {
      title: 'Create a new connection',
      description: 'Connect to another company or location.'
    }
  },

  account: {
    title: 'Your account',
    description: 'Update your account information.',
    deleteUser: {
      buttonText: 'Delete user',
      msgBoxConfirm: {
        text: 'Are you sure you want to delete your user? Your user will not be deleted if it has future bookings.',
        title: 'Delete your user',
        submit: 'Delete my account'
      }
    }
  },

  userGroups: {
    title: 'User groups',
    description: 'Here you can see user groups you are affiliated with.',
    show: 'Show'
  },

  paymentSources: {
    title: 'Saved credit card',
    description: 'Here you have the option to remove, add or update your credit card.',

    noPaymentSources: {
      title: 'No saved credit cards',
      description: 'You have not saved a credit card for this account.'
    },

    paymentSource: {
      remove: 'Remove'
    },

    updatePaymentSourceButton: 'Update credit cards',
    addCreditCardButton: 'Add credit card'
  },

  payments: {
    title: 'Payments',
    description: 'See your previous payments and get the receipt.',

    noPayments: {
      title: 'You have no purchases...',
      description: 'Nothing has been purchased on this account.'
    },

    payment: {
      productType: {
        resourceBooking: 'Booking',
        subscriptionProduct: 'Membership',
        subscriptionProductSignUpFee: 'Membership sign up fee',
        pointTransaction: 'Purchase of points',
        workshop: 'Event booking'
      },

      showBooking: 'View booking',
      showReceipt: 'View receipt',
    }
  },

  agreements: {
    title: 'Conditions',
    description: 'Here you can see the conditions you have accepted.',

    noAgreements: {
      title: 'There are no conditions here...'
    },

    showAgreements: 'Show conditions',
    accepted: "Accepted",
    notAccepted: "Not accepted"
  },

  signOut: 'Sign out',
  reloadPage: 'Reload app',
  faq: {
    title: 'Support & FAQs',
    link: 'Here',
    text: 'you can find support and frequently asked questions.'
  }
}