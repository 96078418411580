<template lang="pug">
div(v-if="showNavigation")
	b-navbar.navigation.d-sm-block.navigation-mobile
		.d-none.d-sm-block.mb-5.mt-3
			img(:src="locationUserDefault && locationUserDefault.logoImageUrl ? locationUserDefault.logoImageUrl : companyLogoUrl || undefined" style="max-width: 150px; width: 100%; margin: 0 auto; display: block")
		b-navbar-nav.flex-row.flex-xl-column.flex-grow-1.justify-content-between.user-layout
			b-nav-item.mb-sm-1(
				link-classes="d-flex flex-align-center"
				:to="{ name: 'User.Home' }")
				i.p-0.text-center.fe.fe-home.icon-lg.text-muted
				span.d-none.d-sm-inline-block.ml-3 {{ $t('components.app.navigation.home') }}

			b-nav-item.mb-sm-1(
				link-classes="d-flex flex-align-center"
				:to="{ name: 'User.Calendar' }")
				i.p-0.text-center.fe.fe-calendar.icon-lg.text-muted
				span.d-none.d-sm-inline-block.ml-3 {{ $t('components.app.navigation.calendar') }}

			b-nav-item.d-sm-none.nav-center-icon(:to="{ name: 'User.ResourceBooking.Create' }")
				i.text-center.fe.fe-plus

			b-nav-item.mb-sm-1(
				link-classes="d-flex flex-align-center"
				:to="{ name: shopLink }")
				i.p-0.text-center.fe.fe-shopping-cart.icon-lg.text-muted
				span.d-none.d-sm-inline-block.ml-3 {{ $t('components.app.navigation.shop.shop') }}

			b-nav-item.mb-sm-1(
				link-classes="d-flex flex-align-center"
				:to="{ name: 'User.Account' }")
				i.p-0.text-center.fe.fe-user.icon-lg.text-muted
				span.d-none.d-sm-inline-block.ml-3 {{ $t('components.app.navigation.account') }}

			b-nav-item.d-none.d-sm-block.mt-3
				b-button(block variant="primary" @click="$router.push({ name: 'User.ResourceBooking.Create' })") {{ $t('components.app.navigation.bookResource') }}

</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GetCompanyLogo, GetLocationUserDefault, GetLocationUserDefaultQuery } from '@/graphql'
import gql from 'graphql-tag';
import { useUserStore } from '@/store/modules/user';

@Component({
	components: {

	},
	apollo: {
		locationUserDefault: {
			query: GetLocationUserDefault
		},
		companyLogoUrl: {
			query: GetCompanyLogo,
			update(data) {
				if (!data.me.company || !data.me.company.logoUrl) {
					return '/img/bookli-logo.svg'
				}
				return data.me.company.logoUrl
			}
		},
		companyUsing: {
			query: gql`
				query GetUsingPointProducts {
					me {
						company {
							id
							usingPointProducts
							usingSubscriptionProducts
							usingEcom
						}
					}
				}
			`,
			update(data: { me: { company: { usingPointProducts: boolean, usingSubscriptionProducts: boolean, usingEcom: boolean }}}) {
				return data.me.company
			}
		}
	}
})
export default class CompanyUsersUserView extends Vue {
	companyLogoUrl = null
	locationUserDefault: null | GetLocationUserDefaultQuery['locationUserDefault'] = null
	User = useUserStore()

	companyUsing = {
		usingPointProducts: false,
		usingSubscriptionProducts: false,
		usingEcom: false
	}

	get showNavigation() {
		if (this.$router.currentRoute.meta?.hideNavigation) return false
		return true
	}

	get shopLink() {
		if (this.companyUsing.usingEcom)
			return 'User.Ecom.Home'
		else {
			return 'User.Shop'
		}
	}
}
</script>

<style lang="sass" scoped>


</style>
