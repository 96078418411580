<template>
  <b-overlay
    :show="$apollo.queries.userData.loading || updateUserLoading"
    variant="transparent"
    spinner-variant='primary'>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <b-form @submit.prevent="updateUserInfo" novalidate>
        <b-row>
          <b-col>
            <b-form-group :label="$t('components.shared.updateUser.firstNameLabel')">
              <validation-provider name="first-name" rules="required" v-slot="{ errors, untouched }">
                <b-form-input
                  :state="untouched === true ? null : errors.length === 0 ? true : false"
                  v-model="updateUserData.firstName"
                  type="text"
                  :placeholder="$t('components.shared.updateUser.firstNamePlaceHolder')"
                  name="first-name"
                />
                <b-form-invalid-feedback :state="errors.length === 0">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group :label="$t('components.shared.updateUser.lastNameLabel')">
              <validation-provider name="last-name" rules="required" v-slot="{ errors, untouched }">
                <b-form-input
                  :state="untouched === true ? null : errors.length === 0 ? true : false"
                  v-model="updateUserData.lastName"
                  type="text"
                  :placeholder="$t('components.shared.updateUser.lastNamePlaceHolder')"
                  name="last-name"
                />
                <b-form-invalid-feedback :state="errors.length === 0">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group>
          <template #label>
            <div class="d-flex">
              <p class="m-0">{{ $t('components.shared.updateUser.phoneLabel') }}</p>
            </div>
          </template>
          <validation-provider name="phoneNumber" rules="required" v-slot="{ errors }">
            <vue-tel-input
              class="phone-number-select"
              v-model="updateUserData.phoneNumber"
              mode="international"
              :inputOptions="vueTelOptions"
              :onlyCountries="phoneCountriesList"
              name="phoneNumber"
            />
            <b-form-invalid-feedback :state="errors.length === 0">{{ errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group :label="$t('components.shared.updateUser.emailLabel')">
          <validation-provider name="email" rules="required" v-slot="{ errors, untouched }">
            <b-form-input
              :state="untouched === true ? null : errors.length === 0 ? true : false"
              v-model="updateUserData.email"
              :placeholder="$t('components.shared.updateUser.emailPlaceHolder')"
              name="email"
            />
            <b-form-invalid-feedback :state="errors.length === 0">{{ errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <div class="strike mt-4 mb-3">
					<span><b>{{ $t('pages.auth.signUpUser.invoiceDetailsTitle') }}</b></span>
				</div>

        <b-form-group>
          <div class="custom-control custom-switch">
            <input class="custom-control-input"
            type="checkbox"
            v-model="isBusinessUser"
            id="isBusinessUserCheckBox"
            />
            <label class="custom-control-label"
            for="isBusinessUserCheckBox">{{ $t('input.businessAccount.toggle') }}</label>
          </div>
        </b-form-group>
        
        <b-form-group v-if="isBusinessUser">
					<template #label>
					  <div class="d-flex">
						<p class="m-0">{{ $t('input.businessAccount.businessName') }}</p>
					  </div>
					</template>
					<validation-provider name="name" rules="required" v-slot="{ errors, untouched }">
					  <b-form-input
						:state="untouched === true ? null : errors.length === 0 ? true : false"
						v-model="updateUserData.businessName"
						type="text"
						:placeholder="$t('input.businessAccount.businessNamePlaceholder')"
						:disabled="updateUserInfoFormDisabled"
					  />
					  <b-form-invalid-feedback :state="errors.length === 0">{{ errors[0] }}</b-form-invalid-feedback>
					</validation-provider>
				  </b-form-group>

        <b-form-group :label="$t('components.shared.updateUser.countryLabel')">
          <v-select
            :options="getCountryOptions()"
            value="value"
            :clearable="false"
            :reduce="x => x.value"
            v-model="updateUserData.country"
          />
        </b-form-group>

        <b-form-group v-if="isBusinessUser">
					<template #label>
					  <div class="d-flex">
						<p class="m-0">{{ $t('input.businessAccount.vatNumber') }}</p>
					  </div>
					</template>
					<validation-provider name="name" rules="required" v-slot="{ errors, untouched }">
					  <b-form-input
						:state="untouched === true ? null : errors.length === 0 ? true : false"
						v-model="updateUserData.vatNumber"
						type="text"
						:placeholder="vatPlaceholder"
						:disabled="updateUserInfoFormDisabled"
					  />
					  <b-form-invalid-feedback :state="errors.length === 0">{{ errors[0] }}</b-form-invalid-feedback>
					</validation-provider>
				</b-form-group>

        <div class="d-flex justify-content-center" v-if="isBusinessUser">
          <small class="text-muted"> {{ $t("pages.auth.signUpUser.businessAddressTitle") }}</small>
        </div>

        <b-form-group :label="$t('input.streetName.label')">
          <validation-provider name="street-name" rules="required" v-slot="{ errors, untouched }">
            <b-form-input
              :state="untouched === true ? null : errors.length === 0 ? true : false"
              v-model="updateUserData.streetName"
              type="text"
              :placeholder="$t('components.shared.updateUser.streetNamePlaceHolder')"
            />
            <b-form-invalid-feedback :state="errors.length === 0">{{ errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-row>
          <b-col>
            <b-form-group :label="$t('input.zipCode.label')">
              <validation-provider name="zip-code" rules="required" v-slot="{ errors, untouched }">
                <b-form-input
                  :state="untouched === true ? null : errors.length === 0 ? true : false"
                  v-model="updateUserData.zipCode"
                  type="text"
                  :placeholder="$t('input.zipCode.placeholder')"
                />
                <b-form-invalid-feedback :state="errors.length === 0">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group :label="$t('input.city.label')">
              <validation-provider name="city" rules="required" v-slot="{ errors, untouched }">
                <b-form-input
                  :state="untouched === true ? null : errors.length === 0 ? true : false"
                  v-model="updateUserData.city"
                  type="text"
                  :placeholder="$t('input.city.placeholder')"
                />
                <b-form-invalid-feedback :state="errors.length === 0">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button
          class="mt-4"
          variant="primary"
          block
          @click="updateUserInfo()"
          size="lg"
          :disabled="invalid || updateUserLoading"
        >
          <span v-if="updateUserLoading">
            <b-spinner class="button-loading" small type="grow" />
            <span>{{ $t('components.shared.updateUser.updateButtonLoading') }}</span>
          </span>
          <span v-else>{{ $t('components.shared.updateUser.updateButton') }}</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import { UserUpdate, GetUpdateUserInfo, SupportedCountries } from '@/graphql'
import { useShopStore } from '@/store/modules/ecom'
import { sendSegmentData, SegmentTypeOfEvent } from '@/segment/segmentData'
import { getCountryOptions, getCountryVatFormats } from '@/utils/getCountryOptions'
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { availableCountryPhoneNumbers } from '@/utils/availableCountryPhoneNumbers'
import { isValidNumber } from 'libphonenumber-js';
import Vue from "vue";

export default Vue.extend({
  components: {
    vSelect,
    VueTelInput
  },
  data() {
    return {
      shopStore: useShopStore(),
      phoneCountryCodeText: null,
      phoneCountriesList: availableCountryPhoneNumbers(),
      updateUserData: {
        id: '',
        firstName: '',
        lastName: '',
        zipCode: null,
        city: null,
        streetName: null,
        phoneNumber: '',
        email: '',
        country: SupportedCountries['Dk'],
        businessName: '',
        vatNumber: ''
      },
      vueTelOptions: {
        placeholder: this.$t('input.phoneNumber.placeholder'),
      },
      updateUserInfoFormDisabled: false,
      updateUserLoading: false,
      isBusinessUser: false
    };
  },
  props: {
    userId: {
      type: String,
      required: false
    }
  },
  apollo: {
    userData: {
      query: GetUpdateUserInfo,
      update(data) {
        this.updateUserData = {...data.user};
        this.isBusinessUser = !!data.user.businessUser;
        this.updateUserData.businessName = data.user.businessUser?.businessName ?? '';
        this.updateUserData.vatNumber = data.user.businessUser?.vatNumber ?? '';
        return data.user;
      },
      variables() {
        return {
          id: this.userId
        };
      },
    },
  },
  computed: {
    vatPlaceholder() {
      const countryCode = this.updateUserData.country;
      const vatFormats = getCountryVatFormats()
      return vatFormats[countryCode] || 'Enter VAT number';
    }
  },
  methods: {
    getCountryOptions,
    getCountryVatFormats,
    async updateUserInfo() {
      this.updateUserLoading = true;

      if (!this.isPhoneDataValid()) {
        this.$bvToast.toast(this.$t('components.shared.updateUserAdmin.phoneNumberError'), {
          title: this.$t('common.error'),
          variant: 'danger'
        });
        this.updateUserLoading = false;
        return;
      }

      const variables = {
        user: this.userId,
        data: {
          firstName: this.updateUserData.firstName,
          lastName: this.updateUserData.lastName,
          zipCode: this.updateUserData.zipCode,
          phoneNumber: this.updateUserData.phoneNumber,
          streetName: this.updateUserData.streetName,
          email: this.updateUserData.email,
          city: this.updateUserData.city,
          country: this.updateUserData.country,
          isBusinessUser: this.isBusinessUser,
          businessName: this.updateUserData.businessName,
          vatNumber: this.updateUserData.vatNumber
        }
      };

      try {
        const updateUser = await this.$apollo.mutate({
          mutation: UserUpdate,
          variables,
          errorPolicy: 'all'
        });

        if (updateUser.errors) {
          this.$bvToast.toast(updateUser.errors[0].message, {
            title: this.$t('common.error'),
            variant: 'danger'
          });
          this.updateUserLoading = false;
          return;
        }

        this.$bvToast.toast(this.$t('components.shared.updateUser.success'), {
          title: this.$t('common.bookli'),
          variant: 'success'
        });

        this.$apollo.queries.userData.refetch();
        this.$emit('done');

        const dataForSegment = {
          first_name: this.updateUserData.firstName,
          last_name: this.updateUserData.lastName,
          email: this.updateUserData.email,
          city: this.updateUserData.city,
          country: this.updateUserData.country,
          phone_number: this.updateUserData.phoneNumber,
          booking_system: 'Bookli',
          zip_code: this.updateUserData.zipCode,
        };

        sendSegmentData(SegmentTypeOfEvent.IdentifyUser, dataForSegment);
      } catch (error) {
        console.error('Error updating user:', error);
      } finally {
        this.updateUserLoading = false;
      }
    },
    isPhoneDataValid() {
      const { phoneNumber } = this.updateUserData;

      if (typeof phoneNumber === 'string') {
        return isValidNumber(phoneNumber);
      } else {
        console.error('Phone number is not a valid string.');
      }

      return Boolean(phoneNumber);
    }
  },
  updated() {
    this.shopStore.clearShoppingBag();
  }
});
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";

.vs__dropdown-toggle {
  border-radius: 16px;
  padding: 5px 6px 7px 6px;
  border: 1px solid #D2DDEC;
}

.input-group {
  .form-control {
    border-radius: 0 16px 16px 0 !important;
  }
}

.vue-tel-input {
  .vti__dropdown {
    .vti__dropdown-item {
      font-family: 'Arial', sans-serif;
      font-size: 14px;
      color: #333;
    }
  }

  .vti__selected-flag {
    font-family: 'Arial', sans-serif;
  }
}

.vti__dropdown-list {
  z-index: 1000;
}

.phone-number-select {
  border-radius: 0.8rem;
  border: 1px solid #D2DDEC;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    border-color: black;
    outline: 1px solid #f00 !important;
    box-shadow: none !important;
  }
}
</style>