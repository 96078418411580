<template lang="pug">
.container-fluid.safe-area-inset-top(:style="{ backgroundColor: companyPrimaryColor || '#C29FD8' }").layout-max-height-full
	header
		.grid.grid-cols-2
			b-col.pl-0
				img.d-sm-none.mt-1(v-if="locationUserDefault || companyLogoUrl" :src="locationUserDefault && locationUserDefault.logoImageUrl ? locationUserDefault.logoImageUrl : companyLogoUrl || undefined" style="max-width: 120px")
			b-col(align="end" cols="auto").m-0.pr-0
				.pr-0 {{ me.user.firstName }}
				b.text-body#current-location-element(v-if="locationUserDefault" align="end") {{ locationUserDefault.name }} 
		b-row
			b-col(align="end")
				ChangeUserDefaultLocation.mt-4.mb-3.change-location-button.float-right(
					align="end"
					type="button"
					:title="$t('components.app.changeUserDefaultLocation.defaultTitle')"
					ref="changeUserDefaultLocation"
				)

	b-overlay.py-4.card-white-bg.card-white-full.shadow-none.layout-max-height-full.home-card-negative-margin
		.grid.grid-cols-1(v-if="locationUserDefault && locationUserDefault.userWelcomeMessage")
			b-card.shadow-none.card-no-padding.m-0.border-primary.mb-4
				b-row.card-padding-small(align-v="center")
					b-col
						h4.m-0 {{ $t('pages.user.home.companyMessage.title', [ locationUserDefault.name ]) }}
					b-col(cols="auto")
						small.text-muted.d-block.text-center {{ $t('pages.user.home.companyMessage.lastUpdated') }}
						small.d-block.text-center {{ locationUserDefault.userWelcomeMessageLastUpdated | moment('DD/MM YYYY') }}

				hr.m-0

				.card-padding-small
					div.overflow-hidden(v-html="locationUserDefault.userWelcomeMessage" :style="{ maxHeight: locationUserInfoMessageViewFull ? 'none' : '57px', 'height': locationUserInfoMessageViewFull ? '100%' : '70px', transition: 'all 200ms ease-in-out', display: 'flex', 'flex-direction': 'column' }")

					div.mx-auto.mt-3.cursor-pointer(style=" text-align: center" @click="locationUserInfoMessageViewFull = !locationUserInfoMessageViewFull")
						div(v-if="locationUserInfoMessageViewFull && ranglistenSignUpLink")
							b-button(
								variant="link"
								:href="ranglistenSignUpLink"
								target="_blank"
								rel="noopener") {{ $t('pages.user.home.ranglistenLogin.text') }}
						i.fe.fe-chevron-up.mr-2(v-if="locationUserInfoMessageViewFull" style="line-height: 1")
						i.fe.fe-chevron-down.mr-2(v-else style="line-height: 1")
						span(v-if="locationUserInfoMessageViewFull") {{ $t('pages.user.home.companyMessage.viewFullTrue') }}&nbsp;
						span(v-else) {{ $t('pages.user.home.companyMessage.viewFullFalse') }}&nbsp;
						span {{ $t('pages.user.home.companyMessage.viewFullMessage') }}

		.grid.grid-cols-2.mb-1-5
			b-card.card-y-padding-extra-small.card-no-border.cursor-pointer.m-0.bg-light.shadow-none(
				@click="$router.push({ name: 'User.ResourceBooking.Create' })")
				div.d-flex.flex-column.justify-items-end.card-components
					img(src="@/assets/img/ball.svg")
					span.mb-0.text-center {{ $t('pages.user.home.cta.createBooking') }}

			b-card.card-y-padding-extra-small.card-no-border.cursor-pointer.m-0.bg-light.shadow-none(
				@click="$router.push({ name: 'User.Workshops' })")
				div.d-flex.flex-column.justify-items-end.card-components
					i.fe.fe-calendar.icon-styling
					span.mb-0.text-center {{ $t('pages.user.home.cta.viewWorkshops') }}

			b-card.card-y-padding-extra-small.card-no-border.cursor-pointer.m-0.bg-light.shadow-none(
				@click="$router.push({ name: 'User.Workshops', params: { preSelectedWorkshopType: 'Coach' } })")
				div.d-flex.flex-column.justify-items-end.card-components
					i.fe.fe-info.icon-styling
					span.mb-0.text-center {{ $t('pages.user.home.cta.buyTraning') }}

			b-card.card-small-padding-extra-small.card-no-border.cursor-pointer.m-0.bg-light.shadow-none(
				@click="$refs['user-friends-modal'].show()")
				div.d-flex.flex-column.justify-items-end.card-components
					i.fe.fe-users.icon-styling
					span.mb-0.text-center {{ $t('pages.user.home.cta.friends') }}

		.grid.grid-cols-1.mb-3(v-if="me.user.bookingNext || resourceBookingCanCreateSmall")
			b-card.bg-light.card-no-padding.card-no-border.shadow-none.mb-0
				b-row.card-padding-small(align-v="center")
					b-col 
						h4.m-0 {{ $t('pages.user.home.bookingNext.title') }}
					b-col(cols="auto")
						b-link.small(:to="{ name: 'User.Calendar' }") {{ $t('pages.user.home.bookingNext.viewCalendar') }}

				b-card.mx-3.mb-3(v-if="me.user.bookingNext")
					ResourceBookingCard(
						v-if="me.user.bookingNext.__typename === 'ResourceBooking'"
						:resourceBookingId="me.user.bookingNext.id")

					WorkshopBookingCard(
						v-if="me.user.bookingNext.__typename === 'WorkshopBooking'"
						:workshopBookingId="me.user.bookingNext.id"
						:selectedDayId="me.user.bookingNext.workshop.nextDay.id")

				b-card.mx-3(v-if="resourceBookingCanCreateSmall")
					.card-padding-small
						p.small {{ $t('pages.user.home.extendBooking.description', [ resourceBookingCanCreateSmall.resource.name ]) }}
						b-button.mt-3(
							size="md"
							variant="primary"
							block
							@click="$router.push({ name: 'User.ResourceBooking.Create', query: { initSettings: initBookingSettingsString } })") {{ $t('pages.user.home.extendBooking.submit') }}
		HomeAd1
		.grid.grid-cols-1.mt-1
			b-card.card-no-padding.card-no-border.m-0.shadow-none.bg-light
				div.card-padding-small.d-flex.align-items-center
					img(src="@/assets/img/coin.svg")
					h4.m-0.ml-3 {{ $t('pages.user.home.pointAccount.title') }}

				hr.m-0

				.card-padding-small(align-v="center" )
					b-row
						b-col
							b-row
								h1.m-0.pl-3 {{ me.pointAccount.pointsAmount }}
								span.ml-3 {{ $t('pages.user.home.pointAccount.pointCount') }}
						b-col(v-if="me.company.usingPointProducts" cols="auto")
							b-link(:to="{ name: 'User.Shop' }")
								span.d-block {{ $t('pages.user.home.pointAccount.buyPointsOne') + ' ' + $t('pages.user.home.pointAccount.buyPointsTwo')}}

					.card-rows-wrapper(v-if="me.userGroupsPointAccount.length")
						hr.my-2
						.card-rows-row(v-for="pointAccount in me.userGroupsPointAccount" class="pb-0 mb-0" v-if="pointAccount.pointsAmount > 0")
							div
								h5.mb-0 {{ pointAccount.userGroup.name }}
									span
									span &nbsp;({{pointAccount.userGroup.company.name}})
							div
								.text-muted {{ pointAccount.pointsAmount }}

						.card-rows-row(v-for="pointAccount in me.pointAccountsOutsideOfCurrentCompany" class="pb-0 mb-0")
							div
								h5.mb-0 {{ pointAccount.company.name }}
							div
								.text-muted {{ pointAccount.pointsAmount }}


			b-card.card-no-padding.shadow-none.bg-light.card-no-border.m-0.mb-4(v-if="me.company.usingSubscriptionProducts || me.userActiveProductSubscription")
				div.card-padding-small.d-flex.align-items-center
					i.fe.fe-user(style="font-size: 18px; line-height: 1")
					h4.m-0.ml-3 {{ $t('pages.user.home.userProductSubscription.title') }}

				div.mx-3.mb-3(v-if="!me.userActiveProductSubscription.length")
					b-button(
						size="sm"
						block
						variant="outline-primary"
						@click="$router.push({ name: 'User.Shop' })") {{ $t('pages.user.home.userProductSubscription.createSubscription') }}

				div(v-else v-for="subscription in me.userActiveProductSubscription")
					b-card.mx-3.mb-3.p-3
						span.m-0.mb-2 {{ subscription.subscriptionProduct.name}} 
						b-row.m-0.mt-2
							i.fe.fe-map-pin.icon-sm.mr-2
							small.mt-1.text-body {{ me.company.name }}
						b-button.mt-3(
							size="sm"
							block
							variant="outline-primary"
							@click="$router.push({ name: 'User.Shop', params: { openSubscriptionProductId: subscription.subscriptionProduct.id } })") {{ $t('pages.user.home.userProductSubscription.viewSubscription') }}

		DownloadAppSmall

	b-modal(
		:title="$t('pages.user.home.friendsModal.title')"
		ref="user-friends-modal"
		title-tag="h4"
		centered
		hide-footer
		size="md")
		UserFriends


</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { GetMeWelcomePage, GetMeWelcomePageQuery, GetCompanyLogo, GetResourceBookingCanCreateSmall, GetResourceBookingCanCreateSmallQuery, GetLocation, GetLocationUserDefaultQuery, GetCompanyPrimaryColor } from '@/graphql'
import ChangeUserDefaultLocation from '@/components/app/changeUserDefaultLocation.vue'
import ResourceBookingCard from '@/components/app/resourceBookingCard.vue'
import WorkshopBookingCard from '@/components/app/workshopBookingCard.vue'
import DownloadAppSmall from '@/components/shared/downloadAppSmall.vue'
import { InitBookingSettings } from './createResourceBooking/create.vue'
import UserFriends from '@/components/app/userFriends.vue'
import HomeAd1 from '@/components/app/homeAd1.vue'
import { useLocationStore } from '@/store/modules/location'
import { useRanglistenStore } from '@/store/modules/ranglisten'

@Component({
	components: {
		ChangeUserDefaultLocation,
		ResourceBookingCard,
		WorkshopBookingCard,
		DownloadAppSmall,
		UserFriends,
		HomeAd1
	},
	apollo: {
		locationUserDefault: {
			query: GetLocation,
			variables() {
				return {
					id: this.LocationStore.currentUserLocation?.id,
				};
			},
			skip() {
				return !this.LocationStore.currentUserLocation?.id;
			},
			update(data) {
				this.LocationStore.getUserLocation()
				return data.location
			}
		},
		companyLogoUrl: {
			query: GetCompanyLogo,
			update(data) {
				if (!data.me.company || !data.me.company.logoUrl) {
					return '/img/bookli-logo.svg'
				}
				return data.me.company.logoUrl
			}
		},
		companyPrimaryColor: {
			query: GetCompanyPrimaryColor,
			update(data) {
				return data.companySettings.primaryColor
			}
		},
		me: {
			query: GetMeWelcomePage,
			fetchPolicy: 'no-cache',
			update(data) {
				this.meLoading = false
				return data.me
			}
		},
		resourceBookingCanCreateSmall: {
			query: GetResourceBookingCanCreateSmall
		}
	}
})
export default class CompanyUsersUserView extends Vue {
	companyPrimaryColor = ''

	companyLogoUrl = null
	resourceBookingCanCreateSmall: GetResourceBookingCanCreateSmallQuery['resourceBookingCanCreateSmall'] = null

	meLoading = true

	LocationStore = useLocationStore()

	locationUserDefault: null | GetLocationUserDefaultQuery['locationUserDefault'] = null

	locationUserInfoMessageViewFull = false

	get initBookingSettingsString() {
		if (!this.resourceBookingCanCreateSmall) return

		const data: InitBookingSettings = {
			resourceId: this.resourceBookingCanCreateSmall.resource.id,
			startDate: this.resourceBookingCanCreateSmall.startDate,
			endDate: this.resourceBookingCanCreateSmall.endDate
		}

		return JSON.stringify(data)
	}

	ranglistenSignUpLink = useRanglistenStore().signUpLink

	me: GetMeWelcomePageQuery['me'] = {
		user: {
			id: '',
			firstName: '',
			lastName: '',
			bookingNext: null,
			email: ''
		},
		company: {
			id: '',
			name: '',
			usingPointProducts: false,
			usingSubscriptionProducts: false,
			usingEcom: false
		},
		userGroupsPointAccount: [],
		franchise: null,
		pointAccount: {
			id: '',
			pointsAmount: 0
		},
		pointAccountsOutsideOfCurrentCompany: [],
		userActiveProductSubscription: []
	}

	created() {
		this.$nextTick(() => {
			if (!this.LocationStore.currentUserLocation) {
				//@ts-ignore
				this.$refs['changeUserDefaultLocation'].show();
			}
		})
		this.$watch(
			() => this.LocationStore.currentUserLocation,
			(newLocation, oldLocation) => {
				if (!newLocation && oldLocation) {
					//@ts-ignore
					this.$refs['changeUserDefaultLocation'].show();
				}
			}
		);
	}


}
</script>

<style lang="sass">

.icon-styling
	font-size: 24px 

.grid
	display: grid
	grid-gap: 1rem

.mb-1-5
	margin-bottom: 1rem

.grid-cols-2
	grid-template-columns: 1fr 1fr

.grid-cols-1
	grid-template-columns: 1fr
	
.card-components
	height: 70px
	justify-content: center 
	align-items: center 

.card-buttons
	border: none
	box-shadow: none
	
.fixed-toast
	width: 100vw
	max-width: 100vw
	min-width: 100vw

.change-location-button
	max-width: 200px

.company-welcome-message
	p
		&:last-child
			margin-bottom: 0

</style>
