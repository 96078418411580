export default {
  subTitle: 'Settings',
  pageTitle: 'Messages',

  useCautionText: 'Below you can customise messages sent to your customers. Editing the messages causes big changes, be careful and test your changes before updating the messages. Read more and find video instructions in the Bookli wiki ',
  readMoreWikiTitle: 'Customise-standard-messages-sent-to-users',
  
  updateMessageButton: 'Update message',
  dataOptionsTitle: 'Data options',
  messageIsUpdatedToast: 'The messages is updates.',
  resetToDefaultInfo: 'In case you wish reset to the default message, remove all text in the text area and click "Update Message". Reload the page in case the default message is not displayed at once.',

  welcomeMessage: {
    title: 'User welcome message (user home page)',
    description: "This message is displayed on the Home Page of the app when the User logs in. This allows you as a club admin to show a custom message to all user's that are logged in to your location. If the message is left empty, no message will be shown. "
  },

  bookingCreated: {
    title: 'When a booking is created (e-mail)',
    description: "This email is sent to User's when they have created a booking. Customising this message will allow you to edit the information sent to everyone that books court's at your location.",
  },

  welcomeUser: {
    title: 'User welcome message (e-mail)',
    description: "This email is sent out to all new User's that registers at your location. Customising this message will allow you to edit the information sent to everyone that registers at your location."
  },

  bookingReminder: {
    title: 'Booking reminder (SMS)',
    description: "This text message is sent the day of the booking. In case of an early morning booking, the text message is sent the evening before. Remember that text messages are expensive, keep it short and simple."
  },
  
}